import React, { useState } from 'react';
import './profile.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const Profile= () => {
    const [passwordData, setPasswordData] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswordData(prev => ({
            ...prev,
            [name]: value
        }));
        setError(''); // Clear error when user types
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Basic validation
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        
        if (passwordData.newPassword.length < 6) {
            setError('Password must be at least 6 characters long');
            return;
        }

        // Handle password change logic here
        console.log('Password change submitted');
    };

    return (
        <div className='container'>
            <Sidebar/>
            <div className='inner-container'>
                <Navbar title={'Profile'}/>
                <div className="settings-container">
            <div className="password-form-container">
                <h2 className="password-form-title">New Password</h2>
                <form onSubmit={handleSubmit} className="password-form">
                    <div className="profile-form-group">
                        <label htmlFor="newPassword">Password</label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={passwordData.newPassword}
                            onChange={handleInputChange}
                            placeholder="Password"
                            className="password-input"
                        />
                    </div>

                    <div className="profile-form-group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={passwordData.confirmPassword}
                            onChange={handleInputChange}
                            placeholder="Password"
                            className="password-input"
                        />
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    <button type="submit" className="change-password-btn">
                        Change Password
                    </button>
                </form>
            </div>
        </div>
            </div>
        </div>
    );
};

export default Profile;