import React, { useState } from 'react';
import './ContactsTable.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const ContactsTable = () => {
  const [contacts] = useState([]);

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Contacts'}/>
            <div className="contacts-container">
      
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Email ID</th>
              <th>Message</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id}>
                <td>{contact.id}</td>
                <td>{contact.name}</td>
                <td>{contact.mobile}</td>
                <td>{contact.email}</td>
                <td>{contact.message}</td>
                <td>
                  {contact.type === 'enquiry' ? (
                    <button className="btn-enquiry">Enquiry</button>
                  ) : (
                    <button className="btn-contact">Contact Us</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </div>
    </div>
  );
};

export default ContactsTable;