export const verifyUser = async () => {
    try {
        const token = sessionStorage.getItem('authToken');
        if (!token) {
            return false;
        }

        const response = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-session-token': token,
                'X-user-key': 'master'
            },
            body: JSON.stringify({
                "id": "",
                "userName": "master",
                "password": "C5v8=l-8<<?a",
                "phoneNumber": "",
                "email": "",
                "otp": ""
            })
        });

        if (!response.ok) {
            return false;
        }

        const data = await response.json();
        if (data.jwt) {
            sessionStorage.setItem('authToken', data.jwt);
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error verifying user:', error);
        return false;
    }
};

// Helper function to parse JWT token
const parseJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return {};
    }
};

export const getStoredAuthToken = () => {
    return localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
};

export const storeAuthToken = (token, rememberMe = false) => {
    if (rememberMe) {
        localStorage.setItem('authToken', token);
    } else {
        sessionStorage.setItem('authToken', token);
    }
};

export const clearAuthToken = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
}; 