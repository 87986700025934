import React, { useState } from 'react';
import './slotmanager.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const SlotManager = () => {
    const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [jobAcceptance] = useState(false);
  const [jobBooked, setJobBooked] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const toggleJobBooked = (index) => {
    const updatedJobBooked = [...jobBooked];
    updatedJobBooked[index] = updatedJobBooked[index] === 0 ? 1 : 0;
    setJobBooked(updatedJobBooked);
  };

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Slot Manager'}/>
            <div className="slot-manager">
      <div className="master-header">
        <button className="new-slot-button">New Slot</button>
      </div>
      <div className="slots">
        <div className="date-selector">
          <span className="label">Select Date:</span>
          <input
            type="date"
            className="date-input"
            value={selectedDate}
            onChange={handleDateChange}
            defaultValue={formattedDate}
          />
        </div>
        <div className="slot-container">
          {[
            { start: '09:00', end: '09:30', index: 0 },
            { start: '09:30', end: '10:00', index: 1 },
            { start: '10:00', end: '10:30', index: 2 },
            { start: '10:30', end: '11:00', index: 3 },
            { start: '11:00', end: '11:30', index: 4 },
            { start: '11:30', end: '12:00', index: 5 },
            { start: '12:00', end: '12:30', index: 6 },
            { start: '12:30', end: '13:00', index: 7 },
            { start: '13:00', end: '13:30', index: 4 },
            { start: '13:30', end: '14:00', index: 4 },
            { start: '14:00', end: '14:30', index: 4 },
            { start: '14:30', end: '15:00', index: 4 },
            { start: '15:00', end: '15:30', index: 4 },
            { start: '15:30', end: '16:00', index: 4 },
            { start: '16:00', end: '16:30', index: 4 },
            { start: '16:30', end: '17:00', index: 4 },
            { start: '17:00', end: '17:30', index: 4 },
            { start: '17:30', end: '18:00', index: 4 }
          ].map((slot, index) => (
            <div key={index} className="slot">
              <div className="time-range">
                <span>{slot.start} - {slot.end}</span>
                <div className="edit-button">
                <img src="/edit.svg" alt="Edit" />
              </div>
              </div>
              <div className="job-acceptance"><span>Job Acceptance</span><span>{jobAcceptance[slot.index]}</span></div>
              <div className="job-booked">
                <span>Job Booked:0</span>
                <div
                  className={`job-booked-toggle ${jobBooked[slot.index] === 1 ? 'on' : ''}`}
                  onClick={() => toggleJobBooked(slot.index)}
                />
              </div>
              
            </div>
          ))}
        </div>
      </div>
    </div>
        </div>
    </div>
  );
};

export default SlotManager;