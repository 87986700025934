import React from 'react';
import './ServicePackage.css';
import Navbar from '../../components/navbar/navbar';
import Sidebar from '../../components/sidebar/sidebar';

const ServicePackages = () => {
  const packagesData = [
    { id: 1, budget: 11, packages: 'NA', status: 'Inactive' },
    { id: 2, budget: 1400, packages: 'NA', status: 'Inactive' },
    { id: 3, budget: 1499, packages: ['Special offers', 'Relax your body', 'A whole New You', 'All in One package', 'Super deals 1100', 'Beauty Gold', 'Unlock Beauty', 'Beauty Pro', 'Purify Your Skin'], status: 'Active' },
    { id: 4, budget: 22, packages: 'NA', status: 'Inactive' },
    { id: 5, budget: 499, packages: ['Wax it!', 'Best Deal', 'Relax while you wax', 'Mani-pedi + Threading', 'Always Be Ready'], status: 'Inactive' },
    { id: 6, budget: 599, packages: ['Wax it!', 'Best Deal', 'Relax while you wax', 'Mani-pedi + Threading', 'Always Be Ready'], status: 'Active' },
  ];

  return (
     <div className='container'>
       <Sidebar/>
       <div className='inner-container'>
         <Navbar title={'Add Budget'}/>
         <div className="service-packages">
      <div className="master-header">
        <button className="new-budget-btn">+ New Budget</button>
      </div>
      <table className="packages-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Budget</th>
            <th>Packages</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {packagesData.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.budget}</td>
              <td>
                {Array.isArray(item.packages) ? (
                  <ul>
                    {item.packages.map((pkg, i) => (
                      <li key={i}>✔️ {pkg}</li>
                    ))}
                  </ul>
                ) : (
                  <span>NA</span>
                )}
              </td>
              <td>
                <span className={`status ${item.status.toLowerCase()}`}>
                  {item.status}
                </span>
              </td>
              <td>
                <button className="edit-btn">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
       </div>
     </div>
  );
};

export default ServicePackages;
