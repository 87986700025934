import React, { useState } from "react";
import './coupons.css'
import Sidebar from "../../components/sidebar/sidebar";
import Navbar from "../../components/navbar/navbar";


const generateCoupons = () => {
  const coupons = [];
  for (let i = 1; i <= 50; i++) {
    coupons.push({
      id: i,
      usedFor: i % 2 === 0 ? "both" : "service",
      code: `COUPON${i}`,
      type: i % 3 === 0 ? "fixed_cart" : i % 2 === 0 ? "free_service" : "first_user",
      amount: i % 3 === 0 ? 50 : i % 2 === 0 ? 0 : 150,
      usage: i % 5,
      couponLimit: i % 7 === 0 ? 1 : "∞",
      userLimit: i % 6 === 0 ? 1 : "∞",
      expiryDate: `202${i % 10}-12-31`,
    });
  }
  return coupons;
};

const Coupons = () => {
  const [coupons] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCoupons = coupons.filter((coupon) => {
    const matchesType = filterType === "all" || coupon.type === filterType;
    const matchesSearch = coupon.code.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesType && matchesSearch;
  });

  return (
    <div className="container">
        <Sidebar/>
        <div className="inner-container">
            <Navbar title={'Coupons'}/>
            <div className="categories-container">
        <div className="master-header">
        
        <button className="new-category-btn">New Coupon</button>
      </div>
      <div className="filters">
        <select className="filter-select" value={filterType} onChange={(e) => setFilterType(e.target.value)}>
          <option value="all">Show All Type</option>
          <option value="free_service">Free Service</option>
          <option value="fixed_cart">Fixed Cart</option>
          <option value="first_user">First User</option>
        </select>
        <input
          type="text"
          placeholder="Filter by Code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        
      </div>

      <table className="coupon-table-container">
        <thead>
          <tr>
            <th>#</th>
            <th>Used For</th>
            <th>Code</th>
            <th>Type</th>
            <th>Coupon Amount</th>
            <th>Usage</th>
            <th>Coupon Limit</th>
            <th>User Limit</th>
            <th>Expiry Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCoupons.map((coupon) => (
            <tr key={coupon.id}>
              <td>{coupon.id}</td>
              <td>{coupon.usedFor}</td>
              <td>{coupon.code}</td>
              <td>{coupon.type}</td>
              <td>{coupon.amount}</td>
              <td>{coupon.usage}</td>
              <td>{coupon.couponLimit}</td>
              <td>{coupon.userLimit}</td>
              <td>{coupon.expiryDate}</td>
              <td>
                <button className="edit-button">Edit</button>
                <button className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
    </div>
  );
};

export default Coupons;
