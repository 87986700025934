import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './sidebar.css'

export default function Sidebar() {
    const [isOpen, setOpen] = useState(true)
    const [activeDropdown, setActiveDropdown] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        const innerContainer = document.querySelector('.inner-container');
        if (innerContainer) {
            if (isOpen) {
                innerContainer.classList.add('expanded');
            } else {
                innerContainer.classList.remove('expanded');
            }
        }
    }, [isOpen]);

    const toggleDropdown = (menu, e) => {
        e.preventDefault(); // Prevent default link behavior
        setActiveDropdown(activeDropdown === menu ? null : menu)
    }

    const handleNavigation = (path, e) => {
        e.preventDefault(); // Prevent default link behavior
        navigate(path);
    }

    return (
        <nav className="sidebar">
            <div className="side-icons">
                <div className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div className='nav-links'>
                <Link 
                    to="/dashboard" 
                    className="nav-link"
                    onClick={(e) => handleNavigation('/dashboard', e)}
                >
                    <img src='/dashboard.svg' alt='dashboard' />
                    {isOpen && <span>Dashboard</span>}
                </Link>

                <div className="nav-item dropdown">
                    <span
                        className="nav-link"
                        onClick={(e) => toggleDropdown('jobs', e)}
                    >
                        <img src='/job.svg' alt='job' />
                        {isOpen && <span className='side-text'><span>Jobs</span> <span>▼</span></span>}
                    </span>
                    {activeDropdown === 'jobs' && (
                        <div className="dropdown-content">
                            <Link 
                                to="/job/today" 
                                className="nav-link"
                                onClick={(e) => handleNavigation('/job/today', e)}
                            >
                                Total Jobs
                            </Link>
                            <Link 
                                to="/job/upcoming" 
                                className="nav-link"
                                onClick={(e) => handleNavigation('/job/upcoming', e)}
                            >
                                Upcoming Jobs
                            </Link>
                            <Link 
                                to="/job/cancelled" 
                                className="nav-link"
                                onClick={(e) => handleNavigation('/job/cancelled', e)}
                            >
                                Cancelled Jobs
                            </Link>
                            <Link 
                                to="/job/create" 
                                className="nav-link"
                                onClick={(e) => handleNavigation('/job/create', e)}
                            >
                                Create Job
                            </Link>
                            <Link 
                                to="/jobs" 
                                className="nav-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/jobs', { replace: true });
                                }}
                            >
                                All Jobs
                            </Link>
                        </div>
                    )}
                </div>

                <div className="nav-item dropdown">
                    <span
                        className="nav-link"
                        onClick={(e) => toggleDropdown('masters', e)}
                    >
                        <img src='/master.svg' alt='master' />
                        {isOpen && <span className='side-text'><span>Masters</span> <span>▼</span></span>}
                    </span>
                    {activeDropdown === 'masters' && (
                        <div className="dropdown-content">
                            {[
                                { path: '/master-category', label: 'Master Category' },
                                { path: '/category', label: 'Category' },
                                { path: '/sub-category', label: 'Sub Category' },
                                { path: '/services', label: 'Services' },
                                { path: '/packages', label: 'Packages' },
                                { path: '/service-providers', label: 'Service Providers' },
                                { path: '/controls', label: 'Controls' },
                                { path: '/users', label: 'Users' },
                                { path: '/customers', label: 'Customers' },
                                { path: '/slot-master', label: 'Slot Master' },
                                { path: '/coupons', label: 'Coupons' },
                                { path: '/hub-master', label: 'HUB Master' },
                                { path: '/blog-master', label: 'Blog Master' }
                            ].map((item) => (
                                <Link
                                    key={item.path}
                                    to={item.path}
                                    className="nav-link"
                                    onClick={(e) => handleNavigation(item.path, e)}
                                >
                                    {item.label}
                                </Link>
                            ))}
                        </div>
                    )}
                </div>

                <div className="nav-item dropdown">
                    <span 
                        className="nav-link"
                        onClick={(e) => toggleDropdown('appearance', e)}
                    >
                        <img src='/appearance.svg' alt='appearance'/>
                        {isOpen && <span className='side-text'><span>Appearance</span> <span>▼</span></span>} 
                    </span>
                    {activeDropdown === 'appearance' && (
                        <div className="dropdown-content">
                            <Link to="/hero-banner-slider" className="nav-link">Hero Banner Slider</Link>
                            <Link to="/offer-banners" className="nav-link">Offer Banners</Link>
                            <Link to="/promotional-banners" className="nav-link">Promotional Banners</Link>
                            <Link to="/most-recommended-services" className="nav-link">Most Recommended Services</Link>
                            <Link to="/add-budget" className="nav-link">Add Budget</Link>
                            <Link to="/tomorrows-offers" className="nav-link">Tomorrow's Offers</Link>
                        </div>
                    )}
                </div>

                <Link 
                    to="/contacts" 
                    className="nav-link"
                    onClick={(e) => handleNavigation('/contacts', e)}
                >
                    <img src='/contact.svg' alt='contact' />
                    {isOpen && <span>Contact</span>}
                </Link>
                <Link 
                    to="/experts-request" 
                    className="nav-link"
                    onClick={(e) => handleNavigation('/experts-request', e)}
                >
                    <img src='/request.svg' alt='request' />
                    {isOpen && <span>Experts Request</span>}
                </Link>
                <Link 
                    to="/product-inventory" 
                    className="nav-link"
                    onClick={(e) => handleNavigation('/product-inventory', e)}
                >
                    <img src='/product.svg' alt='product' />
                    {isOpen && <span>Product Inventory</span>}
                </Link>
                <div className="nav-item dropdown">
                    <span
                        className="nav-link"
                        onClick={(e) => toggleDropdown('global-settings', e)}
                    >
                        <img src='/setting.svg' alt='setting' />
                        {isOpen && <span className='side-text'><span>Global Settings</span> <span>▼</span></span>}
                    </span>
                    {activeDropdown === 'global-settings' && (
                        <div className="dropdown-content">
                            <Link 
                                to="/global-setting/app" 
                                className="nav-link"
                                onClick={(e) => handleNavigation('/global-setting/app', e)}
                            >
                                App Settings
                            </Link>

                            <Link 
                                to="/global-setting/admin" 
                                className="nav-link"
                                onClick={(e) => handleNavigation('/global-setting/admin', e)}
                            >
                                Admin Settings
                            </Link>
                        </div>
                    )}
                </div>
            </div>

            <div className="side-arrow-bottom" onClick={() => { setOpen(!isOpen) }}>
                <img src={isOpen ? '/left.svg' : '/right.svg'} alt='left/right' />
            </div>
        </nav>
    )
}