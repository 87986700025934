import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import './AllJobs.css';

export default function AllJobs() {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('ALL');
    const JOBS_PER_PAGE = 10;
    const [selectedJob, setSelectedJob] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingJob, setEditingJob] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem('authToken');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-session-token': token,
                        'X-user-key': 'master'
                    },
                    body: JSON.stringify({
                        "id": "",
                        "userName": "master",
                        "password": "C5v8=l-8<<?a",
                        "phoneNumber": "",
                        "email": "",
                        "otp": ""
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to verify token');
                }

                const data = await response.json();
                if (data.jwt) {
                    sessionStorage.setItem('authToken', data.jwt);
                } else {
                    throw new Error('Invalid token');
                }
            } catch (error) {
                console.error('Auth error:', error);
                // Don't automatically redirect on error
                // Only redirect if there's no token
                if (!sessionStorage.getItem('authToken')) {
                    navigate('/login');
                }
            }
        };

        checkAuth();
    }, [navigate]);

    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        try {
            const token = sessionStorage.getItem('authToken');
            if (!token) {
                // Don't redirect, just return
                return;
            }

            // First verify to get latest JWT
            const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': token,
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    "id": "",
                    "userName": "master",
                    "password": "C5v8=l-8<<?a",
                    "phoneNumber": "",
                    "email": "",
                    "otp": ""
                })
            });

            if (!verifyResponse.ok) {
                const errorData = await verifyResponse.json();
                // Don't throw error immediately
                console.error('Verify error:', errorData);
                return;
            }

            const verifyData = await verifyResponse.json();
            const newJwt = verifyData.jwt;
            
            if (newJwt) {
                sessionStorage.setItem('authToken', newJwt);
                // Now fetch jobs with the new JWT
                const jobsResponse = await fetch('https://theexperts-admin-apim.azure-api.net/jobs-module/internal/v1/job/getAll', {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-session-token': newJwt,
                        'X-user-key': 'master'
                    }
                });

                if (!jobsResponse.ok) {
                    const errorData = await jobsResponse.json();
                    throw new Error(errorData.message || 'Failed to fetch jobs');
                }

                const jobsData = await jobsResponse.json();
                console.log('Jobs API Response:', jobsData); // Debug log

                // Handle the actual response structure
                if (jobsData && typeof jobsData === 'object') {
                    // If jobsData is the array itself
                    if (Array.isArray(jobsData)) {
                        setJobs(jobsData);
                    }
                    // If jobs are in a 'data' property
                    else if (jobsData.data && Array.isArray(jobsData.data)) {
                        setJobs(jobsData.data);
                    }
                    // If jobs are in a 'jobs' property
                    else if (jobsData.jobs && Array.isArray(jobsData.jobs)) {
                        setJobs(jobsData.jobs);
                    }
                    // If jobs are in a 'result' property
                    else if (jobsData.result && Array.isArray(jobsData.result)) {
                        setJobs(jobsData.result);
                    }
                    // If we can find any array property in the response
                    else {
                        const arrayProperty = Object.values(jobsData).find(value => Array.isArray(value));
                        if (arrayProperty) {
                            setJobs(arrayProperty);
                        } else {
                            console.error('Could not find jobs array in response:', jobsData);
                            setJobs([]);
                        }
                    }
                } else {
                    console.error('Invalid response format:', jobsData);
                    setJobs([]);
                }
            }
        } catch (err) {
            setError(err.message);
            console.error('Error:', err);
            // Don't clear jobs on error
            // setJobs([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchJobDetails = async (jobID) => {
        try {
            // First verify to get latest JWT
            const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    "id": "",
                    "userName": "master",
                    "password": "C5v8=l-8<<?a",
                    "phoneNumber": "",
                    "email": "",
                    "otp": ""
                })
            });

            if (!verifyResponse.ok) {
                throw new Error('Failed to verify user');
            }

            const verifyData = await verifyResponse.json();
            const newJwt = verifyData.jwt;
            sessionStorage.setItem('authToken', newJwt);

            // Now fetch job details with new JWT
            const response = await fetch(`https://theexperts-admin-apim.azure-api.net/jobs-module/internal/v1/job/get/${jobID}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': newJwt,
                    'X-user-key': 'master'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch job details');
            }

            const data = await response.json();
            setSelectedJob(data);
            setShowModal(true);
        } catch (err) {
            console.error('Error fetching job details:', err);
            alert('Failed to fetch job details. Please try again.');
        }
    };

    // Filter jobs based on search and date
    const filteredJobs = Array.isArray(jobs) ? jobs.filter(job => {
        // Only show jobs that are visible (not deleted)
        if (job.visible === false) return false;

        const jobIdString = String(job?.jobID || '');
        const searchTermLower = searchTerm.toLowerCase();
        
        const matchesSearch = 
            jobIdString === searchTerm || 
            jobIdString.includes(searchTerm) || 
            job?.customerName?.toLowerCase().includes(searchTermLower) ||
            job?.customerMobile?.includes(searchTerm);
        
        const matchesFilter = selectedFilter === 'ALL' ? true :
            selectedFilter === 'TODAY' ? new Date(job?.jobDate).toDateString() === new Date().toDateString() :
            selectedFilter === 'UPCOMING' ? new Date(job?.jobDate) > new Date() :
            selectedFilter === 'CANCELLED' ? job?.jobStatus === 'CANCELLED' :
            selectedFilter === 'CREATED' ? job?.jobStatus === 'CREATED' : true;

        return matchesSearch && matchesFilter;
    }) : [];

    // Get current jobs for pagination
    const indexOfLastJob = currentPage * JOBS_PER_PAGE;
    const indexOfFirstJob = indexOfLastJob - JOBS_PER_PAGE;
    const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
    const totalPages = Math.ceil(filteredJobs.length / JOBS_PER_PAGE);

    // Change page
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Scroll to top when page changes
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Update the JobDetailsModal component
    const JobDetailsModal = ({ job, onClose }) => {
        if (!job) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2>Job Details</h2>
                        <button className="close-button" onClick={onClose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="detail-grid">
                            <div className="detail-section">
                                <h3>Customer Information</h3>
                                <div className="detail-row">
                                    <span className="detail-label">Name:</span>
                                    <span className="detail-value">{job.customerName || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Mobile:</span>
                                    <span className="detail-value">{job.customerMobile || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Email:</span>
                                    <span className="detail-value">{job.customerEmail || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Address:</span>
                                    <span className="detail-value">{job.customerAddress || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">City:</span>
                                    <span className="detail-value">{job.customerCity || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Society:</span>
                                    <span className="detail-value">{job.society || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Pincode:</span>
                                    <span className="detail-value">{job.pincode || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="detail-section">
                                <h3>Job Information</h3>
                                <div className="detail-row">
                                    <span className="detail-label">Job ID:</span>
                                    <span className="detail-value">{job.jobID || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Category:</span>
                                    <span className="detail-value">{job.jobCategory || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Date:</span>
                                    <span className="detail-value">{job.jobDate || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Time Slot:</span>
                                    <span className="detail-value">{job.timeslot || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Status:</span>
                                    <span className={`status-badge status-${(job.jobStatus || '').toLowerCase()}`}>
                                        {job.jobStatus || 'N/A'}
                                    </span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Lead Source:</span>
                                    <span className="detail-value">{job.leadSource || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="detail-section">
                                <h3>Payment Details</h3>
                                <div className="detail-row">
                                    <span className="detail-label">Service Cost:</span>
                                    <span className="detail-value">₹{job.serviceCost || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Product Cost:</span>
                                    <span className="detail-value">₹{job.productCost || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Discount:</span>
                                    <span className="detail-value">₹{job.discount || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Payable Amount:</span>
                                    <span className="detail-value">₹{job.payableAmount || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Payment Type:</span>
                                    <span className="detail-value">{job.paymentType || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Payment Mode:</span>
                                    <span className="detail-value">{job.paymentMode || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="detail-section">
                                <h3>Additional Information</h3>
                                <div className="detail-row">
                                    <span className="detail-label">SP ID:</span>
                                    <span className="detail-value">{job.spId || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">SP Mobile:</span>
                                    <span className="detail-value">{job.spMobile || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Job Comment:</span>
                                    <span className="detail-value">{job.jobComment || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Created Date:</span>
                                    <span className="detail-value">{job.createdDate || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Updated Date:</span>
                                    <span className="detail-value">{job.updatedDate || 'N/A'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Add job management functions
    const handleCreateJob = async (jobData) => {
        try {
            const response = await fetch('https://theexperts-admin-apim.azure-api.net/jobs-module/internal/v1/job/create', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify(jobData)
            });

            if (!response.ok) {
                throw new Error('Failed to create job');
            }

            const data = await response.json();
            setJobs(prev => [...prev, data]);
            setSuccessMessage('Job created successfully');
        } catch (err) {
            setError(err.message);
            console.error('Error creating job:', err);
        }
    };

    const handleEditJob = async (job) => {
        setEditingJob(job);
        setIsEditing(true);
    };

    const handleUpdateJob = async (jobData) => {
        try {
            const response = await fetch('https://theexperts-admin-apim.azure-api.net/jobs-module/internal/v1/job/update', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify(jobData)
            });

            if (!response.ok) {
                throw new Error('Failed to update job');
            }

            const updatedJob = await response.json();
            setJobs(prev => prev.map(job => 
                job.jobId === updatedJob.jobId ? updatedJob : job
            ));
            setIsEditing(false);
            setEditingJob(null);
            setSuccessMessage('Job updated successfully');
        } catch (err) {
            setError(err.message);
            console.error('Error updating job:', err);
        }
    };

    const handleDelete = async (jobId) => {
        if (!window.confirm('Are you sure you want to delete this job?')) {
            return;
        }

        try {
            // First verify to get latest JWT
            const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    "id": "",
                    "userName": "master",
                    "password": "C5v8=l-8<<?a",
                    "phoneNumber": "",
                    "email": "",
                    "otp": ""
                })
            });

            if (!verifyResponse.ok) {
                throw new Error('Failed to verify user');
            }

            const verifyData = await verifyResponse.json();
            const newJwt = verifyData.jwt;
            sessionStorage.setItem('authToken', newJwt);

            // Get the current job data
            const currentJob = jobs.find(job => job.jobID === jobId);
            if (!currentJob) {
                throw new Error('Job not found');
            }

            // Update the job with visibility set to false
            const response = await fetch('https://theexperts-admin-apim.azure-api.net/jobs-module/internal/v1/job/update', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': newJwt,
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    ...currentJob,
                    visible: false
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to delete job');
            }

            // Update the local state to reflect the visibility change
            setJobs(prevJobs => prevJobs.map(job => 
                job.jobID === jobId ? { ...job, visible: false } : job
            ));

            setSuccessMessage('Job deleted successfully');
            
            // Clear success message after 3 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);

        } catch (error) {
            console.error('Error updating job visibility:', error);
            setError(error.message || 'Failed to delete job');
            
            // Clear error message after 3 seconds
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    };

    const handleView = (job) => {
        setSelectedJob(job);
        setShowViewModal(true);
    };

    const handleEdit = (job) => {
        setSelectedJob(job);
        setShowEditModal(true);
    };

    // Add these modal components
    const ViewModal = ({ job, onClose }) => {
        if (!job) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2>Job Details</h2>
                        <button className="close-button" onClick={onClose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="detail-grid">
                            <div className="detail-section">
                                <h3>📋 Customer Information</h3>
                                <div className="detail-row">
                                    <span className="detail-label">Name</span>
                                    <span className="detail-value">{job.customerName || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Mobile</span>
                                    <span className="detail-value">{job.customerMobile || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Email</span>
                                    <span className="detail-value">{job.customerEmail || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Address</span>
                                    <span className="detail-value">{job.customerAddress || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">City</span>
                                    <span className="detail-value">{job.customerCity || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Pincode</span>
                                    <span className="detail-value">{job.pincode || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="detail-section">
                                <h3>🔧 Job Information</h3>
                                <div className="detail-row">
                                    <span className="detail-label">Job ID</span>
                                    <span className="detail-value">{job.jobID || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Category</span>
                                    <span className="detail-value">{job.jobCategory || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Date</span>
                                    <span className="detail-value">{job.jobDate || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Time Slot</span>
                                    <span className="detail-value">{job.timeslot || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Status</span>
                                    <span className={`status-badge status-${(job.jobStatus || '').toLowerCase()}`}>
                                        {job.jobStatus || 'N/A'}
                                    </span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Lead Source</span>
                                    <span className="detail-value">{job.leadSource || 'N/A'}</span>
                                </div>
                            </div>

                            <div className="detail-section">
                                <h3>💰 Payment Information</h3>
                                <div className="detail-row">
                                    <span className="detail-label">Service Cost</span>
                                    <span className="detail-value">₹{job.serviceCost || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Product Cost</span>
                                    <span className="detail-value">₹{job.productCost || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Discount</span>
                                    <span className="detail-value">₹{job.discount || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Total Amount</span>
                                    <span className="detail-value">₹{job.totalAmount || '0'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Payment Status</span>
                                    <span className="detail-value">{job.paymentStatus || 'N/A'}</span>
                                </div>
                                <div className="detail-row">
                                    <span className="detail-label">Payment Mode</span>
                                    <span className="detail-value">{job.paymentMode || 'N/A'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const SuccessAnimation = ({ onClose }) => {
        useEffect(() => {
            const timer = setTimeout(() => {
                onClose();
            }, 2000); // Close after 2 seconds

            return () => clearTimeout(timer);
        }, [onClose]);

        return (
            <div className="success-animation-overlay">
                <div className="main-container">
                    <div className="check-container">
                        <div className="check-background">
                            <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="check-shadow"></div>
                    </div>
                </div>
            </div>
        );
    };

    const EditModal = ({ job, onClose }) => {
        const [editedJob, setEditedJob] = useState(job);
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [showSuccess, setShowSuccess] = useState(false);

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsSubmitting(true);

            try {
                // First verify to get latest JWT
                const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-session-token': sessionStorage.getItem('authToken'),
                        'X-user-key': 'master'
                    },
                    body: JSON.stringify({
                        "id": "",
                        "userName": "master",
                        "password": "C5v8=l-8<<?a",
                        "phoneNumber": "",
                        "email": "",
                        "otp": ""
                    })
                });

                if (!verifyResponse.ok) {
                    throw new Error('Failed to verify user');
                }

                const verifyData = await verifyResponse.json();
                const newJwt = verifyData.jwt;
                sessionStorage.setItem('authToken', newJwt);

                // Preserve the existing visibility status when updating
                const currentJob = jobs.find(j => j.jobID === editedJob.jobID);
                const updatedJob = {
                    ...editedJob,
                    visible: currentJob ? currentJob.visible : true // Keep existing visibility or default to true
                };

                // Update job
                const response = await fetch('https://theexperts-admin-apim.azure-api.net/jobs-module/internal/v1/job/update', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-session-token': newJwt,
                        'X-user-key': 'master'
                    },
                    body: JSON.stringify(updatedJob)
                });

                if (!response.ok) {
                    throw new Error('Failed to update job');
                }

                const data = await response.json();
                if (data.success) {
                    setShowSuccess(true);
                    // Update local state while preserving visibility
                    setJobs(prevJobs => prevJobs.map(j => 
                        j.jobID === updatedJob.jobID ? updatedJob : j
                    ));
                    setTimeout(() => {
                        setShowSuccess(false);
                        onClose();
                    }, 2000);
                } else {
                    throw new Error(data.message || 'Failed to update job');
                }
            } catch (error) {
                console.error('Error updating job:', error);
                alert('Failed to update job: ' + error.message);
            } finally {
                setIsSubmitting(false);
            }
        };

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setEditedJob(prev => ({
                ...prev,
                [name]: value
            }));
        };

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2>Edit Job</h2>
                        <button className="close-button" onClick={onClose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-grid">
                                {/* Customer Information Section */}
                                <div className="form-section">
                                    <h3>Customer Information</h3>
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                        <input
                                            type="text"
                                            name="customerName"
                                            value={editedJob.customerName || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile</label>
                                        <input
                                            type="text"
                                            name="customerMobile"
                                            value={editedJob.customerMobile || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            name="customerEmail"
                                            value={editedJob.customerEmail || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Address</label>
                                        <textarea
                                            name="customerAddress"
                                            value={editedJob.customerAddress || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            name="customerCity"
                                            value={editedJob.customerCity || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Pincode</label>
                                        <input
                                            type="text"
                                            name="pincode"
                                            value={editedJob.pincode || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                {/* Job Information Section */}
                                <div className="form-section">
                                    <h3>Job Information</h3>
                                    <div className="form-group">
                                        <label>Job Category</label>
                                        <input
                                            type="text"
                                            name="jobCategory"
                                            value={editedJob.jobCategory || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Job Date</label>
                                        <input
                                            type="date"
                                            name="jobDate"
                                            value={editedJob.jobDate || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Time Slot</label>
                                        <input
                                            type="text"
                                            name="timeslot"
                                            value={editedJob.timeslot || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Status</label>
                                        <select
                                            name="jobStatus"
                                            value={editedJob.jobStatus || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="CREATED">Created</option>
                                            <option value="SCHEDULED">Scheduled</option>
                                            <option value="IN_PROGRESS">In Progress</option>
                                            <option value="COMPLETED">Completed</option>
                                            <option value="CANCELLED">Cancelled</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Lead Source</label>
                                        <input
                                            type="text"
                                            name="leadSource"
                                            value={editedJob.leadSource || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                {/* Payment Information Section */}
                                <div className="form-section">
                                    <h3>Payment Information</h3>
                                    <div className="form-group">
                                        <label>Service Cost</label>
                                        <input
                                            type="number"
                                            name="serviceCost"
                                            value={editedJob.serviceCost || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Product Cost</label>
                                        <input
                                            type="number"
                                            name="productCost"
                                            value={editedJob.productCost || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Discount</label>
                                        <input
                                            type="number"
                                            name="discount"
                                            value={editedJob.discount || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Payment Type</label>
                                        <select
                                            name="paymentType"
                                            value={editedJob.paymentType || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="PREPAID">Prepaid</option>
                                            <option value="POSTPAID">Postpaid</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Payment Mode</label>
                                        <select
                                            name="paymentMode"
                                            value={editedJob.paymentMode || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="CASH">Cash</option>
                                            <option value="ONLINE">Online</option>
                                            <option value="UPI">UPI</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Additional Information Section */}
                                <div className="form-section">
                                    <h3>Additional Information</h3>
                                    <div className="form-group">
                                        <label>SP ID</label>
                                        <input
                                            type="text"
                                            name="spId"
                                            value={editedJob.spId || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>SP Mobile</label>
                                        <input
                                            type="text"
                                            name="spMobile"
                                            value={editedJob.spMobile || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Job Comment</label>
                                        <textarea
                                            name="jobComment"
                                            value={editedJob.jobComment || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-actions">
                                <button type="button" onClick={onClose}>Cancel</button>
                                <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="container">
            <Sidebar />
            <div className="inner-container">
                <Navbar title={"All Jobs"} />
                <div className="jobs-container">
                    <div className="jobs-header">
                        <div className="search-section">
                            <button 
                                className="plus-button"
                                onClick={() => navigate('/job/create')}
                                title="Create New Job"
                            >
                                +
                            </button>
                            <div className="search-filters">
                                <input
                                    type="text"
                                    placeholder="Search by Job ID, name or mobile"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="search-input"
                                />
                                <select
                                    value={selectedFilter}
                                    onChange={(e) => setSelectedFilter(e.target.value)}
                                    className="filter-select"
                                >
                                    <option value="ALL">All Jobs</option>
                                    <option value="TODAY">Total Jobs</option>
                                    <option value="UPCOMING">Upcoming Jobs</option>
                                    <option value="CANCELLED">Cancelled Jobs</option>
                                    <option value="CREATED">Created Jobs</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {successMessage && (
                        <div className="success-message">
                            {successMessage}
                        </div>
                    )}
                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    {loading ? (
                        <div className="loading">Loading jobs...</div>
                    ) : (
                        <>
                            {filteredJobs.length === 0 ? (
                                <div className="no-jobs-message">
                                    No jobs found for {selectedFilter.toLowerCase()} status.
                                    {searchTerm && " Matching search term: " + searchTerm}
                                </div>
                            ) : (
                                <>
                                    <table className="jobs-table">
                                        <thead>
                                            <tr>
                                                <th>Job ID</th>
                                                <th>Customer Name</th>
                                                <th>Mobile</th>
                                                <th>Date</th>
                                                <th>Service Cost</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentJobs.map((job) => (
                                                <tr key={job.jobID}>
                                                    <td>{job.jobID}</td>
                                                    <td>{job.customerName}</td>
                                                    <td>{job.customerMobile}</td>
                                                    <td>{job.jobDate}</td>
                                                    <td>₹{job.serviceCost}</td>
                                                    <td>
                                                        <span className={`status-badge status-${job.jobStatus.toLowerCase()}`}>
                                                            {job.jobStatus}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="action-buttons">
                                                            <button 
                                                                className={`view-button ${loading ? 'button-loading' : ''}`}
                                                                onClick={() => handleView(job)}
                                                                disabled={loading}
                                                            >
                                                                View
                                                            </button>
                                                            <button 
                                                                className={`edit-button ${loading ? 'button-loading' : ''}`}
                                                                onClick={() => handleEdit(job)}
                                                                disabled={loading}
                                                            >
                                                                Edit
                                                            </button>
                                                            <button 
                                                                className={`delete-button ${loading ? 'button-loading' : ''}`}
                                                                onClick={() => handleDelete(job.jobID)}
                                                                disabled={loading}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="pagination">
                                        {/* Previous button */}
                                        <button 
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className="pagination-button"
                                        >
                                            ←
                                        </button>

                                        {/* Page numbers */}
                                        {[...Array(totalPages)].map((_, index) => {
                                            const pageNumber = index + 1;
                                            // Show first page, last page, current page, and one page before and after current
                                            if (
                                                pageNumber === 1 ||
                                                pageNumber === totalPages ||
                                                (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                                            ) {
                                                return (
                                                    <button
                                                        key={pageNumber}
                                                        onClick={() => handlePageChange(pageNumber)}
                                                        className={currentPage === pageNumber ? 'active' : ''}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                );
                                            }
                                            // Show dots if there's a gap
                                            if (
                                                pageNumber === currentPage - 2 ||
                                                pageNumber === currentPage + 2
                                            ) {
                                                return <span key={pageNumber} className="pagination-dots">...</span>;
                                            }
                                            return null;
                                        })}

                                        {/* Next button */}
                                        <button 
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                            className="pagination-button"
                                        >
                                            →
                                        </button>

                                        {/* Page info */}
                                        <div className="pagination-info">
                                            Showing {indexOfFirstJob + 1}-{Math.min(indexOfLastJob, filteredJobs.length)} of {filteredJobs.length} jobs
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            {showModal && <JobDetailsModal job={selectedJob} onClose={() => setShowModal(false)} />}
            {showViewModal && (
                <ViewModal 
                    job={selectedJob} 
                    onClose={() => setShowViewModal(false)} 
                />
            )}
            {showEditModal && (
                <EditModal 
                    job={selectedJob} 
                    onClose={() => setShowEditModal(false)}
                    onSave={(updatedJob) => {
                        // Handle save logic
                        setShowEditModal(false);
                        fetchJobs(); // Refresh the list
                    }}
                />
            )}
        </div>
    );
} 