import React from "react";
import "./promotionalBanner.css";
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";

const PromotionalBannerUpload = () => {
  const banners = [1, 2, 3];

  return (
    <div className="container">
      <Sidebar/>
      <div className="inner-container">
        <Navbar title={'Promotional Banners'}/>
        <div className="promotional-banner-upload">
        {banners.map((banner) => (
            <div className="banner-card" key={banner}>
            <p className="banner-title">Promotional Banner {banner}</p>
            <div className="file-upload-container">
                <label className="file-label">
                    
                    <input type="file" className="file-input" />
                </label>
                <button className="browse-button">Browse</button>
            </div>
            <button className="submit-btn">Submit</button>
            </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default PromotionalBannerUpload;
