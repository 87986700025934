import React, { useState } from 'react';
import './CategoryList.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const MasterCategoryList = () => {
  const [categories, setCategories] = useState([
    { id: 1, image: '', name: 'Make Up', status: 'Active' },
    { id: 2, image: '', name: 'Salon At Home', status: 'Active' }
  ]);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('All');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleDelete = (id) => {
    setCategories(categories.filter(category => category.id !== id));
  };

  const filteredCategories = categories.filter(category => 
    category.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedFilter === 'All' || category.status === selectedFilter)
  );

  const createControl = async (displayName) => {
    try {
        const response = await fetch(`${process.env.ADMIN_URL}/control/create`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-session-token': sessionStorage.getItem('authToken'),
                'X-user-key': 'master'
            },
            body: JSON.stringify({ displayName })
        });

        if (!response.ok) {
            throw new Error('Failed to create control');
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.error('Error creating control:', err);
        throw err;
    }
  };

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Master Categories'}/>
            <div className="master-categories">
      <div className="master-header">
        <button className="new-category-btn">New Category</button>
      </div>
      
      <div className="filters">
        <select 
          className="filter-select"
          value={selectedFilter}
          onChange={handleFilterChange}
        >
          <option value="All">All</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
        
        <input
          type="text"
          placeholder="Search Category"
          className="search-input"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <table className="categories-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Category</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCategories.map((category) => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>
                <img src="" alt={category.name} className="category-image" />
              </td>
              <td>{category.name}</td>
              <td>
                <span className="status-badge">{category.status}</span>
              </td>
              <td className="action-buttons">
                <button className="edit-btn">Edit</button>
                <button 
                  className="delete-btn"
                  onClick={() => handleDelete(category.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
    </div>
  );
};

export default MasterCategoryList;