import React, { useState } from 'react';
import './BlogListing.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const BlogListing = () => {
    const [blogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        // Filter logic can be implemented here
    };

    const handleNewBlog = () => {
        // Handle new blog creation
    };

    return (
        <div className='container'>
            <Sidebar/>
            <div className='inner-container'>
                <Navbar title={'Blog Master'}/>
                <div className="blog-container">
            <div className="master-header">
                <button onClick={handleNewBlog} className="new-blog-btn">
                    New Blog
                </button>
            </div>
            
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Filter by Blog"
                    className="search-input"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>

            <div className="table-container">
                <table className="blog-table">
                    <thead>
                        <tr>
                            <th>Sr No</th>
                            <th>Image</th>
                            <th>Blog Title</th>
                            <th>Author</th>
                            <th>Publish Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {blogs.map((blog, index) => (
                            <tr key={index}>
                                <td>{blog.srNo}</td>
                                <td>
                                    <img src="" alt="Blog thumbnail" className="blog-thumb" />
                                </td>
                                <td>{blog.title}</td>
                                <td>{blog.author}</td>
                                <td>{blog.publishDate}</td>
                                <td>{blog.status}</td>
                                <td>
                                    <div className="action-buttons">
                                        <button className="action-btn">Edit</button>
                                        <button className="action-btn">Delete</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
            </div>
        </div>
    );
};

export default BlogListing;