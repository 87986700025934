import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

// Create context for user data
const AuthContext = createContext(null);

// Create a custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return {
    ...context,
    isAdmin: context.userData?.role === 'ADMIN' || context.userData?.role === 'MASTER',
    isMaster: context.userData?.role === 'MASTER'
  };
};

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'encryption-key';

  // Memoize secureStorage to prevent recreation on each render
  const secureStorage = useMemo(() => ({
    getItem: (key) => {
      try {
        const encryptedData = sessionStorage.getItem(key);
        if (!encryptedData) return null;
        
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
        return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        console.error('Error decrypting data:', error);
        return null;
      }
    },
    clear: () => {
      try {
        sessionStorage.clear();
      } catch (error) {
        console.error('Error clearing storage:', error);
      }
    }
  }), [ENCRYPTION_KEY]);

  // Memoize logout function
  const logout = useCallback(() => {
    secureStorage.clear();
    setUserData(null);
    setLoading(false);
    navigate('/');
  }, [secureStorage, navigate]);

  // Memoize checkAuth function
  const checkAuth = useCallback(() => {
    const storedUserData = secureStorage.getItem('userData');
    const authToken = secureStorage.getItem('authToken');

    if (!storedUserData || !authToken) {
      logout();
      return;
    }

    setUserData(storedUserData);
    setLoading(false);
  }, [secureStorage, logout]);

  // Memoize context value
  const contextValue = useMemo(() => ({
    userData,
    logout,
    secureStorage
  }), [userData, logout, secureStorage]);

  useEffect(() => {
    let mounted = true;

    const init = () => {
      if (mounted) {
        checkAuth();
      }
    };

    init();

    return () => {
      mounted = false;
    };
  }, [checkAuth]);

  if (loading) {
    return <div className="flex items-center justify-center w-full h-screen">Loading...</div>;
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;