import React, { useState, useRef } from 'react';
import './PackageSelector.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const PackageSelector = () => {
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [offerValue, setOfferValue] = useState('65');
  const selectRef = useRef(null);

  const packages = [
    { id: 'wax', name: 'Wax it' },
    { id: 'allinone', name: 'All in One package' },
    { id: 'express', name: 'Express Yourself' },
    { id: 'sitback', name: 'Sit,Back & relax' },
    { id: 'summer', name: 'Summer Deals' },
    { id: 'supersaving', name: 'Super Saving Deals' },
    { id: 'treat', name: 'Treat yourself' }
  ];

  const handlePackageSelection = (event) => {
    const selectedId = event.target.value;
    if (selectedId && !selectedPackages.includes(selectedId)) {
      setSelectedPackages([...selectedPackages, selectedId]);
    }
    if (selectRef.current) {
      selectRef.current.value = '';
    }
  };

  const removePackage = (packageId) => {
    setSelectedPackages(prevPackages => {
      const newPackages = prevPackages.filter(id => id !== packageId);
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.focus();
        }
      }, 0);
      
      return newPackages;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected packages:', selectedPackages);
    console.log('Offer value:', offerValue);
  };

  return (
    <div className='container'>
      <Sidebar/>
      <div className='inner-container'>
        <Navbar title={'Most Recommended Services'}/>
        <div className="package-selector">
      <div className="selector-container">
        <div className="offer-input">
          <label>Recommended Package Offer</label>
          <input
            type="text"
            value={offerValue}
            onChange={(e) => setOfferValue(e.target.value)}
          />
        </div>

        <div className="packages-section">
          <label>Select Packages</label>
          <div className="packages-container">
            <div className="selected-packages">
              {selectedPackages.map(packageId => {
                const pkg = packages.find(p => p.id === packageId);
                return (
                  <div key={packageId} className="package-tag">
                    <span>{pkg.name}</span>
                    <button
                      onClick={() => removePackage(packageId)}
                      className="remove-button"
                    >
                      ×
                    </button>
                  </div>
                );
              })}
            </div>
            <select
              ref={selectRef}
              onChange={handlePackageSelection}
              className="package-select"
            >
              <option value="">Select a package</option>
              {packages.map(pkg => (
                <option key={pkg.id} value={pkg.id}>
                  {pkg.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button className="submit-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
      </div>
    </div>
  );
};

export default PackageSelector;