import React, { useState } from 'react';
import './PackageTable.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const PackageTable = () => {
  const initialData = [
    {
      id: 1,
      packageName: "Premium Spa Package",
      category: "Wellness",
      includes: "Massage, Facial, Pedicure",
      averageTime: 120,
      mrp: 4999,
      offerPrice: 3999,
      discount: 20,
      rating: 4.5,
      inventory: 15
    },
    {
      id: 2,
      packageName: "Hair Treatment Deluxe",
      category: "Hair Care",
      includes: "Hair Spa, Haircut, Styling",
      averageTime: 90,
      mrp: 2999,
      offerPrice: 2499,
      discount: 16.7,
      rating: 4.2,
      inventory: 25
    },
    {
      id: 3,
      packageName: "Bridal Package",
      category: "Bridal",
      includes: "Makeup, Hair, Draping",
      averageTime: 180,
      mrp: 15999,
      offerPrice: 12999,
      discount: 18.8,
      rating: 4.8,
      inventory: 8
    },
    {
      id: 4,
      packageName: "Men's Grooming",
      category: "Men's Care",
      includes: "Haircut, Beard, Facial",
      averageTime: 60,
      mrp: 1999,
      offerPrice: 1499,
      discount: 25,
      rating: 4.3,
      inventory: 30
    },
    {
      id: 5,
      packageName: "Basic Beauty",
      category: "Essential Care",
      includes: "Facial, Threading, Waxing",
      averageTime: 75,
      mrp: 1499,
      offerPrice: 1199,
      discount: 20,
      rating: 4.0,
      inventory: 40
    },
    {
      id: 6,
      packageName: "Skin Radiance",
      category: "Skin Care",
      includes: "Advanced Facial, Cleanup",
      averageTime: 90,
      mrp: 3499,
      offerPrice: 2999,
      discount: 14.3,
      rating: 4.6,
      inventory: 20
    },
    {
      id: 7,
      packageName: "Nail Art Special",
      category: "Nail Care",
      includes: "Manicure, Pedicure, Art",
      averageTime: 120,
      mrp: 2499,
      offerPrice: 1999,
      discount: 20,
      rating: 4.4,
      inventory: 18
    },
    {
      id: 8,
      packageName: "Body Polish",
      category: "Body Care",
      includes: "Scrub, Massage, Wrap",
      averageTime: 150,
      mrp: 5999,
      offerPrice: 4999,
      discount: 16.7,
      rating: 4.7,
      inventory: 12
    },
    {
      id: 9,
      packageName: "Hair Color Package",
      category: "Hair Care",
      includes: "Color, Treatment, Style",
      averageTime: 150,
      mrp: 4499,
      offerPrice: 3699,
      discount: 17.8,
      rating: 4.3,
      inventory: 22
    },
    {
      id: 10,
      packageName: "Makeup Master",
      category: "Makeup",
      includes: "Party Makeup, Hair Style",
      averageTime: 90,
      mrp: 3999,
      offerPrice: 3299,
      discount: 17.5,
      rating: 4.5,
      inventory: 28
    }
  ];

  const [tableData, setTableData] = useState(initialData);

  const handleChange = (id, field, value) => {
    setTableData(prevData =>
      prevData.map(row =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  };

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Product Inventory'}/>
            <div className="table-container">
      <table className="package-table">
        <thead>
          <tr>
            <th>Name of Package</th>
            <th>Category / Packages</th>
            <th>Includes</th>
            <th>Average time for Services (Mins)</th>
            <th>MRP</th>
            <th>Offer Price</th>
            <th>Discount %</th>
            <th>Rating</th>
            <th>Inventory-Remaining</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(row => (
            <tr key={row.id}>
              <td>
                <input
                  type="text"
                  value={row.packageName}
                  onChange={(e) => handleChange(row.id, 'packageName', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.category}
                  onChange={(e) => handleChange(row.id, 'category', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.includes}
                  onChange={(e) => handleChange(row.id, 'includes', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.averageTime}
                  onChange={(e) => handleChange(row.id, 'averageTime', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.mrp}
                  onChange={(e) => handleChange(row.id, 'mrp', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.offerPrice}
                  onChange={(e) => handleChange(row.id, 'offerPrice', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.discount}
                  onChange={(e) => handleChange(row.id, 'discount', e.target.value)}
                  step="0.1"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.rating}
                  onChange={(e) => handleChange(row.id, 'rating', e.target.value)}
                  step="0.1"
                  min="0"
                  max="5"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.inventory}
                  onChange={(e) => handleChange(row.id, 'inventory', e.target.value)}
                  min="0"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </div>
    </div>
  );
};

export default PackageTable;