import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useAuth } from '../../context/AuthContext';

const LoginPage = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  const [input, setInput] = useState('');
  const [inputType, setInputType] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { login } = useAuth();

  
  const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'encryption-key';

  // Wrap secureStorage in useMemo
  const secureStorage = useMemo(() => ({
    setItem: (key, data) => {
      try {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(data),
          ENCRYPTION_KEY
        ).toString();
        sessionStorage.setItem(key, encryptedData);
      } catch (error) {
        console.error('Error encrypting data:', error);
        throw new Error('Failed to store data securely');
      }
    },
    getItem: (key) => {
      try {
        const encryptedData = sessionStorage.getItem(key);
        if (!encryptedData) return null;
        
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY);
        return JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        console.error('Error decrypting data:', error);
        return null;
      }
    },
    removeItem: (key) => {
      try {
        sessionStorage.removeItem(key);
      } catch (error) {
        console.error('Error removing data:', error);
      }
    },
    clear: () => {
      try {
        sessionStorage.clear();
      } catch (error) {
        console.error('Error clearing storage:', error);
      }
    }
  }), [ENCRYPTION_KEY]); // Only recreate if ENCRYPTION_KEY changes

  // Define setupSecurityMeasures before using it in useEffect
  const setupSecurityMeasures = useCallback(() => {
    // Prevent debugging
    const preventDevTools = () => {
      if (window.devtools?.isOpen) {
        secureStorage.clear();
        window.location.href = '/';
      }
    };

    // Handle visibility change (tab switching)
    const handleVisibilityChange = () => {
      if (document.hidden) {
        secureStorage.clear();
      }
    };

    // Handle session timeout
    const setupSessionTimeout = () => {
      const timeout = 30 * 60 * 1000; // 30 minutes
      let timeoutId;

      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          secureStorage.clear();
          window.location.href = '/';
        }, timeout);
      };

      // Reset timeout on user activity
      ['mousedown', 'keydown', 'scroll', 'touchstart'].forEach(event => {
        document.addEventListener(event, resetTimeout);
      });

      resetTimeout();
    };

    // Set up all security measures
    document.addEventListener('visibilitychange', handleVisibilityChange);
    setInterval(preventDevTools, 1000);
    setupSessionTimeout();
  }, [secureStorage]);

  const removeSecurityMeasures = () => {
    // Clean up event listeners here
    document.removeEventListener('visibilitychange', () => {});
  };

  useEffect(() => {
    fetchRoles();
    
    // Clear any existing session on component mount
    secureStorage.clear();

    // Set up security measures
    setupSecurityMeasures();

    return () => {
      // Cleanup on unmount
      removeSecurityMeasures();
    };
  }, [secureStorage, setupSecurityMeasures]);

  useEffect(() => {
    if (!input) {
      setInputType('');
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobilePattern = /^\d{10}$/;

    if (emailPattern.test(input)) {
      setInputType('email');
    } else if (mobilePattern.test(input)) {
      setInputType('mobile');
    } else {
      setInputType('userName');
    }
  }, [input]);

  const fetchRoles = async () => {
    try {
      const response = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/getRoles', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-user-key': 'master'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success && Array.isArray(data.userRoles)) {
        setRoles(data.userRoles);
      } else {
        throw new Error(data.error || 'Invalid data format received');
      }
    } catch (err) {
      setError('Failed to load roles. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const requestBody = {
        id: "",
        userName: inputType === 'userName' ? input : "",
        password: inputType !== 'mobile' ? password : "",
        phoneNumber: inputType === 'mobile' ? input : "",
        email: inputType === 'email' ? input : "",
        otp: inputType === 'mobile' ? otp : "",
      };

      console.log('Sending request:', requestBody); // For debugging

      const response = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-user-key': 'master'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Server error:', errorData); // For debugging
        throw new Error(errorData?.error?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.success && data.user && data.jwt) {
        // Store minimal user data
        const userDataToStore = {
          id: data.user.id,
          role: role,
          fullName: data.user.fullName || input,
          userName: input,
          lastLoginTime: data.user.lastLoginTime
        };

        // Securely store the JWT
        secureStorage.setItem('authToken', data.jwt);
        
        // Store minimal user data
        secureStorage.setItem('userData', userDataToStore);

        // Call login with the user data
        login(userDataToStore);

        // Set up authentication header for future requests
        const setupAuthHeader = () => {
          const token = secureStorage.getItem('authToken');
          if (token) {
            return { 
              'X-session-token': token,
              'X-user-key': 'master'
            };
          }
          return { 'X-user-key': 'master' };
        };

        // Store the headers setup function globally
        window.getAuthHeaders = setupAuthHeader;

        // Redirect to dashboard
        navigate('/dashboard');
      } else {
        if (!data.success) {
          setError(data.error?.message || 'Login failed');
        } else if (!data.user) {
          setError('User data not received. Please try again.');
        } else if (!data.jwt) {
          setError('Authentication failed. Please try again.');
        }
      }
    } catch (err) {
      setError(err.message || 'Network error. Please check your connection and try again.');
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendOTP = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/getOtp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-user-key': 'master'
        },
        body: JSON.stringify({
          phoneNumber: input
        })
      });

      const data = await response.json();
      if (data.success) {
        setOtpSent(true);
        setError(null);
      } else {
        setError(data.error?.message || 'Failed to send OTP');
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
      console.error('OTP error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderInputFields = () => {
    return (
      <>
        <div className="form-group">
          <div className='select-label' style={{display: input ? "block" : "none"}}>
            {inputType === 'email' ? 'Email' : inputType === 'mobile' ? 'Mobile Number' : 'User ID'}
          </div>
          <input
            type={inputType === 'email' ? 'email' : 'text'}
            placeholder="Enter Email / Mobile / User ID"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="form-input"
            required
          />
        </div>

        {inputType === 'mobile' ? (
          !otpSent ? (
            <button 
              type="button" 
              className="submit-button" 
              onClick={handleSendOTP}
              disabled={!input}
            >
              Send OTP
            </button>
          ) : (
            <div className="form-group">
              <div className='select-label' style={{display: otp ? "block" : "none"}}>Enter OTP</div>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="form-input"
                maxLength="6"
                required
              />
            </div>
          )
        ) : (
          <div className="form-group">
            <div className='select-label' style={{display: password ? "block" : "none"}}>Password</div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              required
            />
          </div>
        )}
      </>
    )
  };

  return (
    <div className="login-container">
        <div className="main">
            <div className="logo-container">
                <div className="logo">
                    <img src='/logo.jpg' alt='logo'/>
                </div>
            </div>
            
            <div className="form-container">
                {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
                
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <div className='select-label'>Role</div>
                        <select 
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="form-input"
                            required
                            disabled={isLoading}
                        >
                            <option value="" disabled>Select Role</option>
                            {roles.map((role) => (
                                <option key={role} value={role.toLowerCase()}>
                                    {role.charAt(0) + role.slice(1).toLowerCase()}
                                </option>
                            ))}
                        </select>
                    </div>

                    {renderInputFields()}

                    {(inputType !== 'mobile' || otpSent) && (
                        <button 
                            type="submit" 
                            className="submit-button" 
                            disabled={isLoading}
                        >
                            {isLoading ? 'Loading...' : 'Sign In'}
                        </button>
                    )}
                </form>

                <div className="footer">
                    © 2025 All Rights Reserved. 
                    <a href="/privacy" className="footer-link">Privacy</a> and 
                    <a href="/terms" className="footer-link">Terms</a>
                </div>
            </div>
        </div>
    </div>
  );
};

export default LoginPage;