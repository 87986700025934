import React, { useState } from 'react';
import './Packages.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const Packages = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const packagesPerPage = 10;

  const generatePackages = () => {
    const packages = [];
    const titles = ['Special offers', 'Sit,Back & relax', 'Express Yourself', 'Premium Care', 'Luxury Treatment'];
    const services = [
      ['Underarms', 'VLCC Anti Tan', 'Full Arms', 'Pedicure', 'Full Legs'],
      ['Underarms', 'VLCC Papaya Fruit', 'Full Arms', 'Head(10min)', 'Half Legs', 'Eyebrows & Upperlips'],
      ['Underarms', 'VLCC Insta Glow', 'Full Arms', 'Face', 'Full Legs', 'HEAD(10 Min)', 'Eyebrows & Upperlips']
    ];

    for (let i = 1; i <= 50; i++) {
      packages.push({
        id: i,
        category: 'Salon At Home',
        title: titles[i % titles.length],
        services: services[i % services.length],
        addOn: 'NA',
        discount: Math.floor(Math.random() * 10 + 10) + '%',
        originalPrice: Math.floor(Math.random() * 2000 + 1000),
        timing: Math.floor(Math.random() * 100 + 60),
        status: 'Active'
      });
    }
    return packages;
  };

  const [packages] = useState(generatePackages());

  const filteredPackages = packages.filter(pkg => {
    const matchesSearch = pkg.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = !selectedStatus || pkg.status === selectedStatus;
    const matchesCategory = !selectedCategory || pkg.category === selectedCategory;
    const matchesType = !selectedType || pkg.title.includes(selectedType);
    return matchesSearch && matchesStatus && matchesCategory && matchesType;
  });

  const totalPages = Math.ceil(filteredPackages.length / packagesPerPage);
  const indexOfLastPackage = currentPage * packagesPerPage;
  const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
  const currentPackages = filteredPackages.slice(indexOfFirstPackage, indexOfLastPackage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const calculateDiscountedPrice = (price, discount) => {
    const discountValue = parseInt(discount);
    return Math.round(price - (price * discountValue / 100));
  };

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Packages'}/>
            <div className="packages-container">
            <div className="pack-packages-header">
  <div className="pack-filters">
    <select 
      value={selectedStatus} 
      onChange={(e) => setSelectedStatus(e.target.value)}
      className="pack-filter-select"
    >
      <option value="">Status</option>
      <option value="Active">Active</option>
      <option value="Inactive">Inactive</option>
    </select>

    <select 
      value={selectedCategory}
      onChange={(e) => setSelectedCategory(e.target.value)}
      className="pack-filter-select"
    >
      <option value="">Service Category</option>
      <option value="Salon At Home">Salon At Home</option>
    </select>

    <select 
      value={selectedType}
      onChange={(e) => setSelectedType(e.target.value)}
      className="pack-filter-select"
    >
      <option value="">Package Type</option>
      <option value="Special offers">Special offers</option>
      <option value="Sit,Back & relax">Sit,Back & relax</option>
      <option value="Express Yourself">Express Yourself</option>
    </select>

    <input
      type="text"
      placeholder="Package Name"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="pack-package-search-input"
    />
  </div>

  <div className="pack-action-buttons">
    <button className="pack-export-btn">Export</button>
    <button className="pack-new-package-btn">New Package</button>
  </div>
</div>

      <table className="packages-table">
        <thead>
          <tr>
            <th>#</th>
            <th>M.Category</th>
            <th>Title</th>
            <th>Services</th>
            <th>Add On</th>
            <th>Discount</th>
            <th>Price</th>
            <th>Approx Timing</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPackages.map((pkg) => (
            <tr key={pkg.id}>
              <td>{pkg.id}</td>
              <td>{pkg.category}</td>
              <td>{pkg.title}</td>
              <td className="services-cell">
                {expandedRows[pkg.id] 
                  ? pkg.services.map((service, index) => (
                      <div key={index} className="service-item">✓ {service}</div>
                    ))
                  : <>
                      {pkg.services.map((service, index) => (
                        <div key={index} className="service-item">✓ {service}</div>
                      ))}
                      
                    </>
                }
              </td>
              <td>{pkg.addOn}</td>
              <td>{pkg.discount}</td>
              <td className="price-cell">
                <div className="original-price">₹{pkg.originalPrice}</div>
                <div className="discounted-price">
                  ₹{calculateDiscountedPrice(pkg.originalPrice, pkg.discount)}
                </div>
                <div className="savings">
                  (Save {pkg.originalPrice - calculateDiscountedPrice(pkg.originalPrice, pkg.discount)})
                </div>
              </td>
              <td>{pkg.timing}</td>
              <td>
                <span className={`status-badge ${pkg.status.toLowerCase()}`}>
                  {pkg.status}
                </span>
              </td>
              <td className="action-cell">
                <button className="edit-btn">Edit</button>
                <button className="delete-btn">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button 
          className="pagination-btn"
          disabled={currentPage === 1}
          onClick={() => paginate(currentPage - 1)}
        >
          Previous
        </button>
        
        <div className="pagination-numbers">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              className={`pagination-number ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <button 
          className="pagination-btn"
          disabled={currentPage === totalPages}
          onClick={() => paginate(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
        </div>
    </div>
  );
};

export default Packages;