import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';
import './ServiceListing.css'


const ServiceListing = () => {
    const serviceData = [
        { id: 1, category: "Salon At Home", name: "Eyebrows", subCategory: "Threading", type: "Threading", originalPrice: 35, salePrice: 25, serviceTime: 10, status: "Active" },
        { id: 2, category: "Salon At Home", name: "Upperlips", subCategory: "Threading", type: "Threading", originalPrice: 35, salePrice: 25, serviceTime: 5, status: "Active" },
        { id: 3, category: "Salon At Home", name: "Forehead", subCategory: "Threading", type: "Threading", originalPrice: 35, salePrice: 25, serviceTime: 5, status: "Active" },
        { id: 4, category: "Salon At Home", name: "Chin", subCategory: "Threading", type: "Threading", originalPrice: 35, salePrice: 25, serviceTime: 5, status: "Active" },
        { id: 5, category: "Salon At Home", name: "Sidelock", subCategory: "Threading", type: "Threading", originalPrice: 35, salePrice: 25, serviceTime: 5, status: "Active" },
        { id: 6, category: "Salon At Home", name: "Full Face", subCategory: "Threading", type: "Threading", originalPrice: 299, salePrice: 199, serviceTime: 35, status: "Active" },
        { id: 7, category: "Salon At Home", name: "Full Face", subCategory: "Waxing", type: "Waxing", originalPrice: 450, salePrice: 249, serviceTime: 25, status: "Active" },
        { id: 8, category: "Salon At Home", name: "Chin", subCategory: "Waxing", type: "Waxing", originalPrice: 60, salePrice: 35, serviceTime: 5, status: "Active" },
        { id: 9, category: "Salon At Home", name: "Upperlips", subCategory: "Waxing", type: "Waxing", originalPrice: 60, salePrice: 40, serviceTime: 5, status: "Active" },
        { id: 10, category: "Salon At Home", name: "Sidelock", subCategory: "Waxing", type: "Waxing", originalPrice: 60, salePrice: 35, serviceTime: 10, status: "Active" },
        { id: 11, category: "Salon At Home", name: "Full Arms", subCategory: "Waxing", type: "Waxing", originalPrice: 299, salePrice: 199, serviceTime: 20, status: "Active" },
        { id: 12, category: "Salon At Home", name: "Half Arms", subCategory: "Waxing", type: "Waxing", originalPrice: 199, salePrice: 149, serviceTime: 15, status: "Active" },
        { id: 13, category: "Salon At Home", name: "Full Legs", subCategory: "Waxing", type: "Waxing", originalPrice: 499, salePrice: 349, serviceTime: 30, status: "Active" },
        { id: 14, category: "Salon At Home", name: "Half Legs", subCategory: "Waxing", type: "Waxing", originalPrice: 299, salePrice: 199, serviceTime: 20, status: "Active" },
        { id: 15, category: "Salon At Home", name: "Underarms", subCategory: "Waxing", type: "Waxing", originalPrice: 99, salePrice: 69, serviceTime: 10, status: "Active" },
        { id: 16, category: "Salon At Home", name: "Full Body", subCategory: "Waxing", type: "Waxing", originalPrice: 1999, salePrice: 1499, serviceTime: 90, status: "Active" },
        { id: 17, category: "Hair Care", name: "Hair Cut", subCategory: "Basic", type: "Cutting", originalPrice: 499, salePrice: 299, serviceTime: 45, status: "Active" },
        { id: 18, category: "Hair Care", name: "Hair Spa", subCategory: "Treatment", type: "Spa", originalPrice: 999, salePrice: 699, serviceTime: 60, status: "Active" },
        { id: 19, category: "Hair Care", name: "Hair Color", subCategory: "Coloring", type: "Color", originalPrice: 1499, salePrice: 999, serviceTime: 90, status: "Active" },
        { id: 20, category: "Hair Care", name: "Hair Styling", subCategory: "Styling", type: "Style", originalPrice: 799, salePrice: 599, serviceTime: 45, status: "Active" },
        { id: 21, category: "Facial", name: "Basic Facial", subCategory: "Regular", type: "Facial", originalPrice: 699, salePrice: 499, serviceTime: 45, status: "Active" },
        { id: 22, category: "Facial", name: "Gold Facial", subCategory: "Premium", type: "Facial", originalPrice: 1499, salePrice: 999, serviceTime: 60, status: "Active" },
        { id: 23, category: "Facial", name: "Diamond Facial", subCategory: "Premium", type: "Facial", originalPrice: 1999, salePrice: 1499, serviceTime: 75, status: "Active" },
        { id: 24, category: "Facial", name: "Anti-Aging Facial", subCategory: "Special", type: "Facial", originalPrice: 2499, salePrice: 1799, serviceTime: 90, status: "Active" },
        { id: 25, category: "Massage", name: "Full Body Massage", subCategory: "Massage", type: "Therapy", originalPrice: 1999, salePrice: 1499, serviceTime: 90, status: "Active" },
        { id: 26, category: "Massage", name: "Back Massage", subCategory: "Massage", type: "Therapy", originalPrice: 999, salePrice: 699, serviceTime: 45, status: "Active" },
        { id: 27, category: "Massage", name: "Head Massage", subCategory: "Massage", type: "Therapy", originalPrice: 499, salePrice: 349, serviceTime: 30, status: "Active" },
        { id: 28, category: "Massage", name: "Foot Massage", subCategory: "Massage", type: "Therapy", originalPrice: 599, salePrice: 399, serviceTime: 30, status: "Active" },
        { id: 29, category: "Nail Care", name: "Manicure", subCategory: "Basic", type: "Nails", originalPrice: 499, salePrice: 349, serviceTime: 45, status: "Active" },
        { id: 30, category: "Nail Care", name: "Pedicure", subCategory: "Basic", type: "Nails", originalPrice: 699, salePrice: 499, serviceTime: 60, status: "Active" },
        { id: 31, category: "Nail Care", name: "Nail Art", subCategory: "Premium", type: "Nails", originalPrice: 999, salePrice: 699, serviceTime: 45, status: "Active" },
        { id: 32, category: "Nail Care", name: "Gel Nails", subCategory: "Premium", type: "Nails", originalPrice: 1499, salePrice: 999, serviceTime: 75, status: "Active" },
        { id: 33, category: "Makeup", name: "Party Makeup", subCategory: "Makeup", type: "Beauty", originalPrice: 2999, salePrice: 1999, serviceTime: 90, status: "Active" },
        { id: 34, category: "Makeup", name: "Bridal Makeup", subCategory: "Makeup", type: "Beauty", originalPrice: 9999, salePrice: 7999, serviceTime: 120, status: "Active" },
        { id: 35, category: "Makeup", name: "Regular Makeup", subCategory: "Makeup", type: "Beauty", originalPrice: 1499, salePrice: 999, serviceTime: 60, status: "Active" },
        { id: 36, category: "Bleach", name: "Face Bleach", subCategory: "Bleaching", type: "Bleach", originalPrice: 399, salePrice: 299, serviceTime: 30, status: "Active" },
        { id: 37, category: "Bleach", name: "Full Arms Bleach", subCategory: "Bleaching", type: "Bleach", originalPrice: 599, salePrice: 399, serviceTime: 45, status: "Active" },
        { id: 38, category: "Bleach", name: "Full Body Bleach", subCategory: "Bleaching", type: "Bleach", originalPrice: 1999, salePrice: 1499, serviceTime: 90, status: "Active" },
        { id: 39, category: "Package", name: "Bridal Package", subCategory: "Premium", type: "Package", originalPrice: 19999, salePrice: 14999, serviceTime: 240, status: "Active" },
        { id: 40, category: "Package", name: "Pre-Wedding Package", subCategory: "Premium", type: "Package", originalPrice: 9999, salePrice: 7999, serviceTime: 180, status: "Active" },
        { id: 41, category: "Package", name: "Party Ready Package", subCategory: "Regular", type: "Package", originalPrice: 4999, salePrice: 3499, serviceTime: 150, status: "Active" },
        { id: 42, category: "Men's Grooming", name: "Men's Haircut", subCategory: "Hair", type: "Cutting", originalPrice: 399, salePrice: 299, serviceTime: 30, status: "Active" },
        { id: 43, category: "Men's Grooming", name: "Beard Trim", subCategory: "Hair", type: "Trimming", originalPrice: 199, salePrice: 149, serviceTime: 20, status: "Active" },
        { id: 44, category: "Men's Grooming", name: "Clean Shave", subCategory: "Hair", type: "Shaving", originalPrice: 199, salePrice: 149, serviceTime: 25, status: "Active" },
        { id: 45, category: "Men's Grooming", name: "Hair Color", subCategory: "Hair", type: "Color", originalPrice: 999, salePrice: 699, serviceTime: 60, status: "Active" },
        { id: 46, category: "Kids", name: "Kids Haircut", subCategory: "Hair", type: "Cutting", originalPrice: 299, salePrice: 199, serviceTime: 30, status: "Active" },
        { id: 47, category: "Kids", name: "Kids Styling", subCategory: "Hair", type: "Styling", originalPrice: 399, salePrice: 299, serviceTime: 45, status: "Active" },
        { id: 48, category: "Special", name: "De-Tan Pack", subCategory: "Skin", type: "Treatment", originalPrice: 999, salePrice: 699, serviceTime: 45, status: "Active" },
        { id: 49, category: "Special", name: "Anti-Acne Treatment", subCategory: "Skin", type: "Treatment", originalPrice: 1499, salePrice: 999, serviceTime: 60, status: "Active" },
        { id: 50, category: "Special", name: "Skin Brightening", subCategory: "Skin", type: "Treatment", originalPrice: 1999, salePrice: 1499, serviceTime: 75, status: "Active" }
      ];
    
      const [services] = useState(serviceData);
      const [displayCount, setDisplayCount] = useState(10);
      const [filters, setFilters] = useState({
        status: '',
        category: '',
        subCategory: '',
        type: '',
        searchTerm: ''
      });
    
      const getUniqueValues = (field) => {
        return [...new Set(services.map(service => service[field]))];
      };
    
      const filteredServices = services.filter(service => {
        return (
          (!filters.status || service.status === filters.status) &&
          (!filters.category || service.category === filters.category) &&
          (!filters.subCategory || service.subCategory === filters.subCategory) &&
          (!filters.type || service.type === filters.type) &&
          (!filters.searchTerm || 
            service.name.toLowerCase().includes(filters.searchTerm.toLowerCase()))
        );
      });
    
      const handleFilterChange = (filterType, value) => {
        setFilters(prev => ({
          ...prev,
          [filterType]: value
        }));
        setDisplayCount(10); 
      };
    
      const handleShowMore = () => {
        setDisplayCount(prev => prev + 10);
      };
    
      const createServiceControl = async (displayName) => {
        try {
            const response = await fetch(`${process.env.ADMIN_URL}/control/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({ displayName })
            });

            if (!response.ok) {
                throw new Error('Failed to create service control');
            }

            const data = await response.json();
            return data;
        } catch (err) {
            console.error('Error creating service control:', err);
            throw err;
        }
    };
    

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Services'}/>
            <div className="service-container">
      <div className="master-header">
        <div className="service-actions">
          <button className="add-new">Add New</button>
          <button className="service-type">Service Type</button>
          <button className="secondary-category">Secondary Category</button>
        </div>
      </div>

      <div className="service-filters">
        <select 
          className="filter-select"
          onChange={(e) => handleFilterChange('status', e.target.value)}
        >
          <option value="">Status</option>
          {getUniqueValues('status').map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>

        <select 
          className="filter-select"
          onChange={(e) => handleFilterChange('category', e.target.value)}
        >
          {getUniqueValues('category').map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>

        <select 
          className="filter-select"
          onChange={(e) => handleFilterChange('subCategory', e.target.value)}
        >
          <option value="">Sub Category</option>
          {getUniqueValues('subCategory').map(subCategory => (
            <option key={subCategory} value={subCategory}>{subCategory}</option>
          ))}
        </select>

        <select 
          className="filter-select"
          onChange={(e) => handleFilterChange('type', e.target.value)}
        >
          <option value="">Type</option>
          {getUniqueValues('type').map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>

        <input
          type="text"
          className="search-input"
          placeholder="Service Name"
          onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
        />
      </div>

      <table className="service-table">
        <thead>
          <tr>
            <th>#</th>
            <th>M. Category</th>
            <th>Image</th>
            <th>Service Name</th>
            <th>Sub Category</th>
            <th>Type</th>
            <th>Amount</th>
            <th>serviceTime</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredServices.slice(0, displayCount).map((service) => (
            <tr key={service.id}>
              <td>{service.id}</td>
              <td>{service.category}</td>
              <td>
                <div className="service-image"></div>
              </td>
              <td>{service.name}</td>
              <td>{service.subCategory}</td>
              <td>{service.type}</td>
              <td>
                <span className="original-price">₹{service.originalPrice}</span>
                <span className="sale-price">₹{service.salePrice}</span>
              </td>
              <td>{service.serviceTime}</td>
              <td>
                <span className="status-badge">{service.status}</span>
                <span className="sale-badge">Sale</span>
              </td>
              <td className="action-buttons">
                <button className="edit-button">Edit</button>
                <button className="delete-button">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {filteredServices.length > displayCount && (
        <button className="show-more" onClick={handleShowMore}>
          Show More
        </button>
      )}
    </div>
        </div>
    </div>
  );
};

export default ServiceListing;