import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './graph.css';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Graph = () => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          font: {
            size: 14
          }
        }
      },
      title: {
        display: true,
        text: 'Company Performance',
        font: {
          size: 24
        },
        padding: 20
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 1250,
        ticks: {
          stepSize: 250,
          font: {
            size: 14
          }
        },
        grid: {
          drawBorder: false
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 14
          }
        }
      }
    }
  };

  const data = {
    labels: ['2004', '2005', '2006', '2007'],
    datasets: [
      {
        label: 'Sales',
        data: [1000, 1150, 700, 1020],
        borderColor: '#1d4ed8',
        backgroundColor: '#1d4ed8',
        tension: 0.3,
        pointRadius: 4,
        pointHoverRadius: 8,
      },
      {
        label: 'Expenses',
        data: [400, 500, 1100, 550],
        borderColor: '#dc2626',
        backgroundColor: '#dc2626',
        tension: 0.3,
        pointRadius: 4,
        pointHoverRadius: 8,
      }
    ]
  };

  return (
    <div className="chart-container">
      <div className="chart-content">
        <Line options={options} data={data} />
      </div>
    </div>
  );
};

export default Graph;