import React, { useState } from 'react';
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import './Controls.css';

export default function Controls() {
    const [displayName, setDisplayName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const handleCreateControl = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        setSuccessMessage('');

        try {
            const response = await fetch('https://theexperts-admin-apim.azure-api.net/admin-module/internal/v1/control/create', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({ displayName })
            });

            // Log response details for debugging
            console.log('Response status:', response.status);
            console.log('Response headers:', Object.fromEntries(response.headers.entries()));

            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new Error(`API returned ${response.status}: Not a JSON response`);
            }

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.message || `API error: ${response.status}`);
            }

            setSuccessMessage('Control created successfully');
            setDisplayName(''); // Clear the input
            
            // Log success data
            console.log('Control created:', data);
        } catch (err) {
            setError(err.message || 'Failed to create control');
            console.error('Error creating control:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <Sidebar />
            <div className="inner-container">
                <Navbar title={"Controls"} />
                <div className="controls-container">
                    <h1>Create Control</h1>
                    
                    {error && <div className="error-message">{error}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    
                    <form onSubmit={handleCreateControl} className="control-form">
                        <div className="form-group">
                            <label htmlFor="displayName">Display Name:</label>
                            <input
                                type="text"
                                id="displayName"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                placeholder="Enter display name"
                                required
                                disabled={isLoading}
                            />
                        </div>
                        <button 
                            type="submit" 
                            disabled={isLoading || !displayName.trim()}
                            className="submit-button"
                        >
                            {isLoading ? 'Creating...' : 'Create Control'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
} 