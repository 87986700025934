import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import { useAuth } from '../../context/AuthContext'
import { clearAuthToken } from '../../utils/auth';

const formatUsername = (username) => {
    if (!username) return '';
    return username.split(/[\s_-]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

const Navbar = (props) => {
  const { logout, user } = useAuth();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [cooldownActive, setCooldownActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  // Check if there's a stored cooldown timestamp
  useEffect(() => {
    const cooldownEndTime = localStorage.getItem('updateCooldownEnd');
    if (cooldownEndTime) {
      const remainingTime = parseInt(cooldownEndTime) - Date.now();
      if (remainingTime > 0) {
        setCooldownActive(true);
        setTimeLeft(remainingTime);
        startCooldownTimer(remainingTime);
      } else {
        localStorage.removeItem('updateCooldownEnd');
      }
    }
  }, []);

  const startCooldownTimer = (duration) => {
    setCooldownActive(true);
    setTimeLeft(duration);

    const timer = setInterval(() => {
      setTimeLeft(prev => {
        const newTime = prev - 1000;
        if (newTime <= 0) {
          clearInterval(timer);
          setCooldownActive(false);
          localStorage.removeItem('updateCooldownEnd');
          return 0;
        }
        return newTime;
      });
    }, 1000);
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      
      // First verify to get latest JWT
      const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-session-token': sessionStorage.getItem('authToken'),
          'X-user-key': 'master'
        },
        body: JSON.stringify({
          "id": "",
          "userName": "master",
          "password": "C5v8=l-8<<?a",
          "phoneNumber": "",
          "email": "",
          "otp": ""
        })
      });

      if (!verifyResponse.ok) {
        throw new Error('Failed to verify user');
      }

      const verifyData = await verifyResponse.json();
      const newJwt = verifyData.jwt;
      
      if (!newJwt) {
        throw new Error('No JWT received from verification');
      }

      sessionStorage.setItem('authToken', newJwt);

      const response = await fetch('https://theexperts-admin-apim.azure-api.net/admin-module/internal/v1/db/sync', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-session-token': newJwt,
          'X-user-key': 'master'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to sync database');
      }

      const data = await response.json();
      if (data.success) {
        alert('Database sync completed successfully');
        // Start 15-minute cooldown
        const cooldownDuration = 15 * 60 * 1000; // 15 minutes in milliseconds
        const cooldownEndTime = Date.now() + cooldownDuration;
        localStorage.setItem('updateCooldownEnd', cooldownEndTime.toString());
        startCooldownTimer(cooldownDuration);
      } else {
        throw new Error(data.message || 'Sync failed');
      }
    } catch (error) {
      console.error('Update error:', error);
      if (error.message.includes('verify')) {
        logout();
      } else {
        alert('Failed to sync database: ' + error.message);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  // Format remaining time for display
  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);
    return `${minutes}m ${seconds}s`;
  };

  const toggleDropdown = (menu) => {
    setActiveDropdown(activeDropdown === menu ? null : menu)
  }

  const handleLogout = () => {
    clearAuthToken();
    window.location.href = '/login';
  };

  const formatRole = (role) => {
    return role ? role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() : '';
  };

  const getDisplayName = () => {
    if (!user) return '';
    return formatUsername(user.userName) || '';
  };

  return (
    <nav className="navbar">
        <h1 className='nav-title'>{props.title}</h1>
        <div className="nav-right">
          <div className="update-button-wrapper">
            <button 
              className="update-button" 
              onClick={handleUpdate}
              disabled={isUpdating || cooldownActive}
              title={cooldownActive ? `You can sync only once in 15 mins. Time remaining: ${formatTimeLeft()}` : ''}
            >
              {isUpdating ? 'Updating...' : cooldownActive ? `Wait ${formatTimeLeft()}` : 'Update'}
            </button>
          </div>
          <div className="user-profile dropdown" onClick={() => toggleDropdown('profile')}>
            <div className="profile-image">
              <img src="/profile.svg" alt="Profile" />
            </div>
            <div className="profile-info">
              <span className="user-name">{getDisplayName()}</span>
              <span className="user-role">{formatRole(user?.role)}</span>
            </div>
            {activeDropdown === 'profile' && (
              <div className="dropdown-content profile-dropdown">
                <Link to="/profile" className="nav-link">Profile</Link>
                <Link onClick={handleLogout} className="nav-link">Log Out</Link>
              </div>
            )}
          </div>
        </div>
    </nav>
  );
};

export default Navbar;