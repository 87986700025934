import React, { useState } from 'react';
import './HubMaster.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const HubMaster = () => {
    const [cities] = useState(['City', 'Delhi', 'Gurugram', 'Manesar', 'Palam Vihar']);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedHub, setSelectedHub] = useState('');
    
    const initialData = [
        { id: 1, city: 'Gurugram', hub: 'Sector 10', convence: 50 },
        { id: 2, city: 'Gurugram', hub: 'Sector 68', convence: 150 },
        { id: 3, city: 'Gurugram', hub: 'Sector 70', convence: 0 },
        { id: 4, city: 'Gurugram', hub: 'Sector 72', convence: 80 },
        { id: 5, city: 'Gurugram', hub: 'Sector 74', convence: 50 },
        { id: 6, city: 'Gurugram', hub: 'Sector 75', convence: 50 },
        { id: 7, city: 'Gurugram', hub: 'Sector 77', convence: 0 },
        { id: 8, city: 'Gurugram', hub: 'Sector 79', convence: 50 },
        { id: 9, city: 'Gurugram', hub: 'Sector 49', convence: 80 },
        { id: 10, city: 'Gurugram', hub: 'Sector 81', convence: 0 },
        // Sample data for other cities
        { id: 11, city: 'Delhi', hub: 'Sector 1', convence: 60 },
        { id: 12, city: 'Manesar', hub: 'Industrial Area', convence: 70 },
        { id: 13, city: 'Palam Vihar', hub: 'Block A', convence: 55 }
    ];

    const [filteredData, setFilteredData] = useState(initialData);

    const handleCityChange = (e) => {
        const value = e.target.value === 'City' ? '' : e.target.value;
        setSelectedCity(value);
        filterData(value, selectedHub);
    };

    const handleHubChange = (e) => {
        setSelectedHub(e.target.value);
        filterData(selectedCity, e.target.value);
    };

    const filterData = (city, hub) => {
        let filtered = [...initialData];
        
        if (city) {
            filtered = filtered.filter(item => 
                item.city.toLowerCase() === city.toLowerCase()
            );
        }
        
        if (hub) {
            filtered = filtered.filter(item => 
                item.hub.toLowerCase().includes(hub.toLowerCase())
            );
        }
        
        setFilteredData(filtered);
    };

    const getFilteredHubs = () => {
        if (selectedCity) {
            return [...new Set(initialData
                .filter(item => item.city === selectedCity)
                .map(item => item.hub))];
        }
        return [...new Set(initialData.map(item => item.hub))];
    };

    return (
        <div className='container'>
            <Sidebar/>
            <div className='inner-container'>
                <Navbar title={'HUB Master'}/>
                <div className="hub_master_container">
            <div className="hub_master_header">
                <div className="hub_master_buttons">
                    <button className="hub_master_button hub_master_button_blue">
                        New HUB
                    </button>
                    <button className="hub_master_button hub_master_button_blue">
                        New City
                    </button>
                </div>
            </div>
            
            <div className="hub_master_filters">
                <select 
                    className="hub_master_select"
                    value={selectedCity || 'City'}
                    onChange={handleCityChange}
                >
                    {cities.map((city, index) => (
                        <option key={index} value={city}>
                            {city}
                        </option>
                    ))}
                </select>
                
                <select 
                    className="hub_master_select"
                    value={selectedHub}
                    onChange={handleHubChange}
                >
                    <option value="">HUB</option>
                    {getFilteredHubs().map((hub, index) => (
                        <option key={index} value={hub}>{hub}</option>
                    ))}
                </select>
            </div>
            
            <div className="hub_master_table_container">
                <table className="hub_master_table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>City</th>
                            <th>HUB</th>
                            <th>Convence</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.city}</td>
                                <td>{item.hub}</td>
                                <td>{item.convence}</td>
                                <td>
                                    <button className="hub_master_edit_button">
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
            </div>
        </div>
    );
};

export default HubMaster;