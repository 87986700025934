import React, { useState } from 'react';
import './CreateJob.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const services = [
    { id: 1, name: "Basic Facial", type: "service", cost: 899 },
    { id: 2, name: "Deep Cleansing", type: "service", cost: 1299 },
    { id: 3, name: "Anti-Aging Treatment", type: "service", cost: 1599 },
    { id: 4, name: "Hydrating Facial", type: "service", cost: 999 },
    { id: 5, name: "Body Massage", type: "service", cost: 1499 },
    { id: 6, name: "Manicure", type: "service", cost: 499 },
    { id: 7, name: "Pedicure", type: "service", cost: 699 },
    { id: 8, name: "Hair Spa", type: "service", cost: 1299 },
    { id: 9, name: "Hair Color", type: "service", cost: 1999 },
    { id: 10, name: "Waxing", type: "service", cost: 799 },
    { id: 11, name: "Threading", type: "service", cost: 199 },
    { id: 12, name: "Makeup", type: "service", cost: 2499 },
    { id: 13, name: "Nail Art", type: "service", cost: 899 },
    { id: 14, name: "Hair Cut", type: "service", cost: 599 },
    { id: 15, name: "Head Massage", type: "service", cost: 699 },
    
    { id: 16, name: "Bridal Package", type: "package", cost: 15999 },
    { id: 17, name: "Pre-Wedding Glow", type: "package", cost: 8999 },
    { id: 18, name: "Party Ready", type: "package", cost: 4999 },
    { id: 19, name: "Complete Makeover", type: "package", cost: 7999 },
    { id: 20, name: "Relaxation Package", type: "package", cost: 3999 },
    { id: 21, name: "Hair Care Package", type: "package", cost: 2999 },
    { id: 22, name: "Skin Care Package", type: "package", cost: 3499 },
    { id: 23, name: "Beauty Essentials", type: "package", cost: 2499 },
    { id: 24, name: "Luxury Spa Day", type: "package", cost: 6999 },
    { id: 25, name: "Monthly Maintenance", type: "package", cost: 4499 },
    { id: 26, name: "Weekend Refresh", type: "package", cost: 1999 },
    { id: 27, name: "Special Occasion", type: "package", cost: 5999 },
    { id: 28, name: "Festival Ready", type: "package", cost: 4999 },
    { id: 29, name: "Summer Glow", type: "package", cost: 3999 },
    { id: 30, name: "Winter Care", type: "package", cost: 3999 }
  ];

const CreateJob = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [discount, setDiscount] = useState(0);
    const [concession, setConcession] = useState(0);
    const [convenience, setConvenience] = useState(0);
  const [formData, setState] = useState({
    mobile: '',
    customerName: '',
    email: '',
    city: '',
    hub: '',
    society: '',
    category: '',
    address: '',
    source: ''
  });
  const timeSlots = [
    "09:00 - 09:30", "09:30 - 10:00", "10:00 - 10:30", "10:30 - 11:00",
    "11:00 - 11:30", "11:30 - 12:00", "12:00 - 12:30", "12:30 - 13:00",
    "13:00 - 13:30", "13:30 - 14:00", "14:00 - 14:30", "14:30 - 15:00",
    "15:00 - 15:30", "15:30 - 16:00", "16:00 - 16:30", "16:30 - 17:00",
    "17:00 - 17:30", "17:30 - 18:00"
  ];

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeSlotSelect = (slot) => {
    setSelectedTimeSlot(slot);
  };

  const handleAddItem = (type) => {
    setShowDropdown(true);
    setSearchTerm('');
  };

  const handleSelectItem = (item) => {
    setSelectedItems([...selectedItems, { ...item, quantity: 1 }]);
    setShowDropdown(false);
    setSearchTerm('');
  };

  const handleQuantityChange = (id, newQuantity) => {
    setSelectedItems(selectedItems.map(item => 
      item.id === id ? { ...item, quantity: parseInt(newQuantity) } : item
    ));
  };

  const handleRemoveItem = (id) => {
    setSelectedItems(selectedItems.filter(item => item.id !== id));
  };

  const calculateTotals = () => {
    const serviceCost = selectedItems.reduce((sum, item) => 
      sum + (item.cost * item.quantity), 0);
    const discountAmount = parseFloat(discount) || 0;
    const concessionAmount = parseFloat(concession) || 0;
    const convenienceAmount = parseFloat(convenience) || 0;
    
    return {
      serviceCost,
      payableAmount: serviceCost - discountAmount - concessionAmount + convenienceAmount
    };
  };

  const filteredItems = services.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const { serviceCost, payableAmount } = calculateTotals();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={"Create Job"}/>
            <div className="customer-form-container">
      <h2>Customer Info</h2>
      <form onSubmit={handleSubmit}>
        
          <div className="form-group">
            <label htmlFor="mobile">
              Mobile <span className="required">*</span>
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="Mobile"
            />
          </div>

          <div className="form-group">
            <label htmlFor="customerName">
              Customer Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="customerName"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              placeholder="Name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
          </div>
        

       
          <div className="form-group">
            <label htmlFor="city">
              Select City <span className="required">*</span>
            </label>
            <select
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            >
              <option value="">City</option>
              <option value="city1">City 1</option>
              <option value="city2">City 2</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="hub">
              Select HUB <span className="required">*</span>
            </label>
            <select
              id="hub"
              name="hub"
              value={formData.hub}
              onChange={handleChange}
            >
              <option value="">Select Hub</option>
              <option value="hub1">Hub 1</option>
              <option value="hub2">Hub 2</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="society">
              Society <span className="required">*</span>
            </label>
            <input
              type="text"
              id="society"
              name="society"
              value={formData.society}
              onChange={handleChange}
              placeholder="Society"
            />
          </div>

          <div className="form-group">
            <label htmlFor="category">
              Category <span className="required">*</span>
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              <option value="">Salon At Home</option>
              <option value="category1">Category 1</option>
              <option value="category2">Category 2</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="address">
              Address <span className="required">*</span>
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Address"
            />
          </div>

          <div className="form-group">
            <label htmlFor="source">
              Source <span className="required">*</span>
            </label>
            <select
              id="source"
              name="source"
              value={formData.source}
              onChange={handleChange}
            >
              <option value="">WhatsApp</option>
              <option value="source1">Source 1</option>
              <option value="source2">Source 2</option>
            </select>
          </div>
        
      </form>
    </div>
    <div className="booking-container">
      <div className="top-section">
        <div className="item-selection">
          <div className="buttons">
            <button className="add-service" onClick={() => handleAddItem('service')}>
              Add Service
            </button>
            <button className="add-package" onClick={() => handleAddItem('package')}>
              Add Package
            </button>
          </div>
          
          {showDropdown && (
            <div className="search-dropdown">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search services/packages..."
              />
              <div className="dropdown-items">
                {filteredItems.map(item => (
                  <div
                    key={item.id}
                    className="dropdown-item"
                    onClick={() => handleSelectItem(item)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          
          
        </div>
      

      <div className="booking-details">
        <div className="date-selection">
          <label>Date</label>
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>

        <div className="time-slots">
          <label>Select Slot</label>
          <div className="slots-grid">
            {timeSlots.map(slot => (
              <button
                key={slot}
                className={`slot ${selectedTimeSlot === slot ? 'selected' : ''}`}
                onClick={() => handleTimeSlotSelect(slot)}
              >
                {slot}
              </button>
            ))}
          </div>
        </div>
      </div>
      </div>

      <div className="cost-summary">
      <table className="items-table">
            <thead>
              <tr>
                <th>Item Details</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedItems.map(item => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>
                    <input
                      type="number"
                      min="1"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                    />
                  </td>
                  <td>₹{item.cost}</td>
                  <td>₹{item.cost * item.quantity}</td>
                  <td>
                    <button className='cross-button' onClick={() => handleRemoveItem(item.id)}>×</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        <div className="cost-item">
          <label>Service Cost</label>
          <span>₹{serviceCost}</span>
        </div>
        <div className="cost-item">
          <label>Discount</label>
          <input
            type="number"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
        </div>
        <div className="cost-item">
          <label>Concession</label>
          <input
            type="number"
            value={concession}
            onChange={(e) => setConcession(e.target.value)}
          />
        </div>
        <div className="cost-item">
          <label>Convenience</label>
          <input
            type="number"
            value={convenience}
            onChange={(e) => setConvenience(e.target.value)}
          />
        </div>
        <div className="cost-item total">
          <label>Payable Amount</label>
          <span>₹{payableAmount}</span>
        </div>
      </div>

      <button className="create-lead">Create Lead</button>
    </div>
        </div>
    </div>
  );
};

export default CreateJob;