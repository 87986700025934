
import React, { useState } from 'react';
import './CategoryList.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const CategoryList = () => {
  const [categories, setCategories] = useState([
    {
      id: 1,
      masterCategory: 'Salon At Home',
      category: 'Pre Bridal',
      image: '',
      subCategories: ['One Sitting Pre Bridal', 'Two Sitting Pre Bridal'],
      saveUpto: '50%',
      status: 'Active'
    },
    {
      id: 2,
      masterCategory: 'Make Up',
      category: 'Makeup',
      image: '',
      subCategories: [
        'Basic Makeup',
        'Drapping',
        'Engagement Makeup',
        'Makeup + Hair style'
      ],
      saveUpto: 'NA',
      status: 'Active'
    },
    {
      id: 3,
      masterCategory: 'Make Up',
      category: 'Hair styling',
      image: '',
      subCategories: [
        'Basic Hair Do',
        'Hair Do Advance'
      ],
      saveUpto: 'NA',
      status: 'Active'
    },
    {
      id: 4,
      masterCategory: 'Make Up',
      category: 'Drapping',
      image: '',
      subCategories: ['Drapping'],
      saveUpto: 'NA',
      status: 'Active'
    },
    {
      id: 5,
      masterCategory: 'Salon At Home',
      category: 'Waxing Deal',
      image: '',
      subCategories: [
        'Best Deals',
        'Self Care',
        'Summer Deals'
      ],
      saveUpto: 'SALE%',
      status: 'Active'
    },
    {
      id: 6,
      masterCategory: 'Salon At Home',
      category: 'Make Your Own Package',
      image: '',
      subCategories: ['Customize package'],
      saveUpto: 'Freedom to customize %',
      status: 'Active'
    },
    {
      id: 7,
      masterCategory: 'Salon At Home',
      category: 'KarvaChauth Special Deals',
      image: '',
      subCategories: [
        'Most demanded',
        'Festival special',
        'Head to Toe',
        'Premium Packages'
      ],
      saveUpto: 'SPECIAL DEALS%',
      status: 'Active'
    },
    {
      id: 8,
      masterCategory: 'Salon At Home',
      category: 'Facial Services',
      image: '',
      subCategories: [
        'Basic Facial',
        'Premium Facial',
        'Anti-Aging Facial',
        'Gold Facial'
      ],
      saveUpto: '40%',
      status: 'Active'
    },
    {
      id: 9,
      masterCategory: 'Make Up',
      category: 'Wedding Packages',
      image: '',
      subCategories: [
        'Bridal Makeup',
        'Family Package',
        'Group Discount'
      ],
      saveUpto: '35%',
      status: 'Active'
    },
    {
      id: 10,
      masterCategory: 'Salon At Home',
      category: 'Hair Care',
      image: '',
      subCategories: [
        'Hair Spa',
        'Hair Color',
        'Hair Treatment',
        'Hair Cut'
      ],
      saveUpto: '25%',
      status: 'Active'
    },
    {
      id: 11,
      masterCategory: 'Make Up',
      category: 'Party Makeup',
      image: '',
      subCategories: [
        'Light Makeup',
        'Heavy Makeup',
        'Theme Based'
      ],
      saveUpto: '30%',
      status: 'Active'
    },
    {
      id: 12,
      masterCategory: 'Salon At Home',
      category: 'Nail Care',
      image: '',
      subCategories: [
        'Manicure',
        'Pedicure',
        'Nail Art',
        'Gel Polish'
      ],
      saveUpto: '20%',
      status: 'Active'
    },
    {
      id: 13,
      masterCategory: 'Make Up',
      category: 'Professional Photoshoot',
      image: '',
      subCategories: [
        'Portfolio Shoot',
        'Event Coverage',
        'Product Photography'
      ],
      saveUpto: '45%',
      status: 'Active'
    },
    {
      id: 14,
      masterCategory: 'Salon At Home',
      category: 'Massage Services',
      image: '',
      subCategories: [
        'Full Body Massage',
        'Head Massage',
        'Foot Massage',
        'Aromatherapy'
      ],
      saveUpto: '15%',
      status: 'Active'
    },
    {
      id: 15,
      masterCategory: 'Make Up',
      category: 'Special Effects',
      image: '',
      subCategories: [
        'Halloween Makeup',
        'Character Makeup',
        'Prosthetic Makeup'
      ],
      saveUpto: 'NA',
      status: 'Active'
    }
  ]);

  const [statusFilter, setStatusFilter] = useState('');
  const [masterCategoryFilter, setMasterCategoryFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const masterCategories = ['Salon At Home', 'Make Up'];
  
  const handleDelete = (id) => {
    setCategories(categories.filter(category => category.id !== id));
  };

  const filteredCategories = categories.filter(category => {
    const matchesStatus = !statusFilter || category.status === statusFilter;
    const matchesMasterCategory = !masterCategoryFilter || 
      category.masterCategory === masterCategoryFilter;
    const matchesSearch = !searchTerm || 
      category.category.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesStatus && matchesMasterCategory && matchesSearch;
  });

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Categories'}/>
            <div className="categories-container">
      <div className="master-header">
        
        <button className="new-category-btn">New Category</button>
      </div>

      <div className="master-filters">
        <select 
          className="master-filter-select"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="">Status</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>

        <select 
          className="master-filter-select"
          value={masterCategoryFilter}
          onChange={(e) => setMasterCategoryFilter(e.target.value)}
        >
          <option value="">Master Category</option>
          {masterCategories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>

        <div className="search-container">
          <input
            type="text"
            placeholder="Category Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      <div className="table-container">
        <table className="categories-table">
          <thead>
            <tr>
              <th>#</th>
              <th>M. Category</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Save Upto</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCategories.map((category) => (
              <tr key={category.id}>
                <td>{category.id}</td>
                <td>{category.masterCategory}</td>
                <td>
                  <div className="category-cell">
                    <img src="" alt={category.category} className="category-image" />
                    <span>{category.category}</span>
                  </div>
                </td>
                <td>
                  <ul className="sub-categories">
                    {category.subCategories.map((sub, index) => (
                      <li key={index}>✓ {sub}</li>
                    ))}
                  </ul>
                </td>
                <td>{category.saveUpto}</td>
                <td>
                  <span className="status-badge">{category.status}</span>
                </td>
                <td id="action-buttons-container">
                  <div className='action-buttons'>
                  <button className="action-btn edit">Edit</button>
                  <button 
                    className="action-btn delete"
                    onClick={() => handleDelete(category.id)}
                  >
                    Delete
                  </button>
                  <button className="action-btn view">View</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </div>
    </div>
  );
};

export default CategoryList;