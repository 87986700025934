import React, { useState } from 'react';
import './ServiceProvider.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const ServiceProvider = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [serviceProviders] = useState([
        {
            id: 1,
            userId: 'TE1299',
            password: '12345',
            spName: 'Sobha',
            contactNo: '9518468893',
            email: 'abc@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 2,
            userId: 'TE1623',
            password: '12345',
            spName: 'Sapna Bhadouria',
            contactNo: '7251849001',
            email: 'sapnabhadouriabhadouria@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 3,
            userId: 'TE2042',
            password: '12345',
            spName: 'Varsha',
            contactNo: '9643822282',
            email: 'abc@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 4,
            userId: 'TE2405',
            password: '12345',
            spName: 'Sita',
            contactNo: '7479158177',
            email: 'abc@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 5,
            userId: 'TE2608',
            password: '12345',
            spName: 'Meenu Bhardwaj',
            contactNo: '9911515565',
            email: 'experts@experts.in',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 6,
            userId: 'TE2643',
            password: '12345',
            spName: 'Shivani Savita',
            contactNo: '8527034288',
            email: 'savitashivani019@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 7,
            userId: 'TE3739',
            password: '12345',
            spName: 'Laxmi',
            contactNo: '7414050907',
            email: 'Pintusain607@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 8,
            userId: 'TE5451',
            password: '12345',
            spName: 'Mona',
            contactNo: '9205503036',
            email: 'theexperts0622@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 9,
            userId: 'TE5523',
            password: '12345',
            spName: 'Kritika Rawat',
            contactNo: '8299573181',
            email: 'kritikarawat14march@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 10,
            userId: 'TE5789',
            password: '12345',
            spName: 'Ritu Sharma',
            contactNo: '9876543210',
            email: 'ritusharma@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 11,
            userId: 'TE5901',
            password: '12345',
            spName: 'Priya Gupta',
            contactNo: '8765432109',
            email: 'priyagupta@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 12,
            userId: 'TE6023',
            password: '12345',
            spName: 'Neha Singh',
            contactNo: '7654321098',
            email: 'nehasingh@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 13,
            userId: 'TE6234',
            password: '12345',
            spName: 'Anjali Verma',
            contactNo: '6543210987',
            email: 'anjaliverma@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 14,
            userId: 'TE6456',
            password: '12345',
            spName: 'Pooja Rani',
            contactNo: '5432109876',
            email: 'poojarani@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 15,
            userId: 'TE6678',
            password: '12345',
            spName: 'Sunita Devi',
            contactNo: '4321098765',
            email: 'sunitadevi@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 16,
            userId: 'TE6789',
            password: '12345',
            spName: 'Meenakshi Kumari',
            contactNo: '3210987654',
            email: 'meenakshikumari@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 17,
            userId: 'TE6901',
            password: '12345',
            spName: 'Deepika Yadav',
            contactNo: '2109876543',
            email: 'deepikayadav@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 18,
            userId: 'TE7012',
            password: '12345',
            spName: 'Kavita Sharma',
            contactNo: '1098765432',
            email: 'kavitasharma@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Inactive'
        },
        {
            id: 19,
            userId: 'TE7123',
            password: '12345',
            spName: 'Radha Kumari',
            contactNo: '9876543211',
            email: 'radhakumari@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        },
        {
            id: 20,
            userId: 'TE7234',
            password: '12345',
            spName: 'Asha Devi',
            contactNo: '8765432112',
            email: 'ashadevi@gmail.com',
            city: 'Gurgaon',
            category: 'Beauty',
            status: 'Active'
        }
    ]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredProviders = serviceProviders.filter(provider =>
        provider.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        provider.spName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        provider.contactNo.includes(searchTerm) ||
        provider.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className='container'>
            <Sidebar/>
            <div className='inner-container'>
                <Navbar title={'Service Providers'}/>
                <div className="sp-container">
            <div className="master-header">
                
                <div className="sp-actions">
                    <button className="sp-category-btn">Category</button>
                    <button className="sp-add-btn">Add New</button>
                </div>
            </div>
            
            <div className="sp-search-container">
                <p className="sp-total">Total Result : {filteredProviders.length}</p>
                <input 
                    type="text"
                    className="sp-search"
                    placeholder="Filter by user name / name / mobile / email id"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>

            <div className="sp-table-container">
                <table className="sp-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>Password</th>
                            <th>SP Name</th>
                            <th>Contact No</th>
                            <th>Email</th>
                            <th>City</th>
                            <th>Category</th>
                            <th>✓</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProviders.map((provider, index) => (
                            <tr key={provider.id}>
                                <td>{index + 1}</td>
                                <td>{provider.userId}</td>
                                <td>{provider.password}</td>
                                <td>{provider.spName}</td>
                                <td>{provider.contactNo}</td>
                                <td>{provider.email}</td>
                                <td>{provider.city}</td>
                                <td>{provider.category}</td>
                                <td>✓</td>
                                <td>
                                    <span className={`status-badge ${provider.status.toLowerCase()}`}>
                                        {provider.status}
                                    </span>
                                </td>
                                <td className="action-buttons">
                                    <button className="btn-active">Active</button>
                                    <button className="btn-inactive">Inactive</button>
                                    <button className="btn-edit">Edit</button>
                                    <button className="btn-delete">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
            </div>
        </div>
    );
};

export default ServiceProvider;