import React, { useState } from 'react';
import './CustomerManagement.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const CustomerManagement = () => {
  // Sample data generation
  const generateCustomers = () => {
    const customers = [];
    const emails = [
      'shefali.mehta2639@gmail.com',
      'theexpert50622@gmail.com',
      'rinku-rinku@hotmail.com',
      'mah.sho2310@gmail.com',
      'monaleesa86.15@gmail.com'
    ];
    
    for (let i = 1; i <= 50; i++) {
      customers.push({
        id: i,
        name: [`Tarun Kumar`, `Neetu Singh`, `Mahima`, `Nidhi`, ``][Math.floor(Math.random() * 5)],
        mobileNo: Math.random().toString().slice(2, 12),
        email: emails[Math.floor(Math.random() * emails.length)],
        jobs: Math.floor(Math.random() * 15),
        revenue: Math.floor(Math.random() * 20000),
        outstanding: Math.floor(Math.random() * 1000) - 500,
        walletAmount: 0,
        referelCode: Math.random().toString(36).substring(2, 12),
        status: ''
      });
    }
    return customers;
  };

  const [customers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredCustomers = customers.filter(customer => {
    const searchMatch = 
      customer.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.mobileNo.includes(searchTerm);
    
    if (statusFilter) {
      return searchMatch && customer.status === statusFilter;
    }
    return searchMatch;
  });

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={"Customers"}/>
            <div className="customer-management">
      <div className="master-header">
        <button className="customer-management__new-user">New User</button>
      </div>

      <div className="customer-management__filters">
        <select 
          className="customer-management__status-filter"
          value={statusFilter}
          onChange={handleStatusChange}
        >
          <option value="">Status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>

        <div className="customer-management__search">
          <input
            type="text"
            placeholder="Search Something"
            value={searchTerm}
            onChange={handleSearch}
            className="customer-management__search-input"
          />
        </div>
      </div>

      <div className="customer-management__table-container">
        <table className="customer-management__table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Email</th>
              <th>Jobs</th>
              <th>Revenue</th>
              <th>Outstanding</th>
              <th>Wallet Amount</th>
              <th>Referel Code</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.id}</td>
                <td>{customer.name}</td>
                <td>{customer.mobileNo}</td>
                <td>{customer.email}</td>
                <td>{customer.jobs}</td>
                <td>{customer.revenue}</td>
                <td>{customer.outstanding}</td>
                <td>{customer.walletAmount}</td>
                <td>{customer.referelCode}</td>
                <td>{customer.status}</td>
                <td>
                  <button className="customer-management__action-btn">
                  <div className="customer-hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </div>
    </div>
  );
};

export default CustomerManagement;