import React, { useState, useEffect, useMemo } from 'react';
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import { useAuth } from '../../components/private/private';
import Cookies from 'js-cookie';
import './Users.css';

export default function Users() {
    const { userData } = useAuth();
    const [formData, setFormData] = useState({
        userId: '',
        phoneNumber: '',
        email: '',
        password: '',
        fullName: '',
        role: 'USER',
        visible: true,
        imageUrl: '',
        otp: '',
        jsonToken: '',
        signedUpDt: '',
        lastLoginTime: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterRole, setFilterRole] = useState('ALL');
    const [editingUser, setEditingUser] = useState(null);
    const [editFormData, setEditFormData] = useState(null);

    // Check for admin rights
    const isAdmin = userData?.role === 'ADMIN' || userData?.role === 'MASTER';

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isAdmin) {
            setError('Access denied: Only ADMIN and MASTER roles can create users');
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccessMessage('');

        try {
            // Create user
            const createResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/user/create', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    userId: formData.userId,
                    phoneNumber: formData.phoneNumber,
                    email: formData.email,
                    password: formData.password,
                    imageUrl: "",
                    otp: "",
                    fullName: formData.fullName,
                    role: formData.role,
                    jsonToken: "",
                    signedUpDt: new Date().toISOString(),
                    lastLoginTime: "",
                    visible: true
                })
            });

            // Log the response for debugging
            console.log('Create User Response:', {
                status: createResponse.status,
                statusText: createResponse.statusText
            });

            const createResponseData = await createResponse.text();
            console.log('Create User Response Data:', createResponseData);

            if (!createResponse.ok) {
                let errorMessage;
                try {
                    const errorData = JSON.parse(createResponseData);
                    errorMessage = errorData.message || 'Failed to create user';
                } catch (e) {
                    errorMessage = createResponseData || 'Failed to create user';
                }
                throw new Error(errorMessage);
            }

            const createdUser = createResponseData ? JSON.parse(createResponseData) : null;

            // Verify user
            const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    userId: formData.userId,
                    password: formData.password,
                    phoneNumber: "",
                    email: "",
                    otp: ""
                })
            });

            // Log verify response for debugging
            console.log('Verify User Response:', {
                status: verifyResponse.status,
                statusText: verifyResponse.statusText
            });

            const verifyResponseData = await verifyResponse.text();
            console.log('Verify User Response Data:', verifyResponseData);

            if (!verifyResponse.ok) {
                let errorMessage;
                try {
                    const errorData = JSON.parse(verifyResponseData);
                    errorMessage = errorData.message || 'Failed to verify user';
                } catch (e) {
                    errorMessage = verifyResponseData || 'Failed to verify user';
                }
                throw new Error(errorMessage);
            }

            const verifiedData = verifyResponseData ? JSON.parse(verifyResponseData) : null;

            // Save token in cookie and sessionStorage
            if (verifiedData?.jwt) {
                Cookies.set('authToken', verifiedData.jwt, { expires: 1 }); // Expires in 1 day
                sessionStorage.setItem('authToken', verifiedData.jwt);
                sessionStorage.setItem('userKey', formData.userId);
            }

            // Add user to list
            if (createdUser) {
                setUsersList(prev => [...prev, { 
                    ...formData, 
                    id: createdUser.id,
                    jwt: verifiedData?.jwt 
                }]);
            }

            setSuccessMessage('User created and verified successfully');

            // Reset form
            setFormData({
                userId: '',
                phoneNumber: '',
                email: '',
                password: '',
                fullName: '',
                role: 'USER',
                visible: true,
                imageUrl: '',
                otp: '',
                jsonToken: '',
                signedUpDt: '',
                lastLoginTime: ''
            });
        } catch (err) {
            console.error('Error details:', err);
            setError(err.message || 'Failed to create/verify user');
        } finally {
            setIsLoading(false);
        }
    };

    const filteredUsers = useMemo(() => {
        return usersList.filter(user => {
            const matchesSearch = (
                user.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            const matchesRole = filterRole === 'ALL' || user.role === filterRole;
            return matchesSearch && matchesRole;
        });
    }, [usersList, searchTerm, filterRole]);

    const handleEdit = (user) => {
        setEditingUser(user);
        setEditFormData({ ...user });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/user/update`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': sessionStorage.getItem('userKey')
                },
                body: JSON.stringify(editFormData)
            });

            if (!response.ok) {
                throw new Error('Failed to update user');
            }

            const updatedUser = await response.json();
            setUsersList(prev => prev.map(user => 
                user.id === updatedUser.id ? updatedUser : user
            ));
            setEditingUser(null);
            setEditFormData(null);
            setSuccessMessage('User updated successfully');
        } catch (err) {
            setError(err.message || 'Failed to update user');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (userId) => {
        if (!window.confirm('Are you sure you want to delete this user?')) {
            return;
        }

        try {
            const response = await fetch(`https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/user/delete/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'X-session-token': sessionStorage.getItem('authToken'),
                    'X-user-key': sessionStorage.getItem('userKey')
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete user');
            }

            setUsersList(prev => prev.filter(user => user.userId !== userId));
            setSuccessMessage('User deleted successfully');
        } catch (err) {
            setError(err.message || 'Failed to delete user');
        }
    };

    if (!isAdmin) {
        return (
            <div className="container">
                <Sidebar />
                <div className="inner-container">
                    <Navbar title={"Users Management"} />
                    <div className="error-message">Access Denied: Only ADMIN and MASTER roles can access this page</div>
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <Sidebar />
            <div className="inner-container">
                <Navbar title={"Users Management"} />
                <div className="users-container">
                    <div className="users-header">
                        <h1>Create New User</h1>
                    </div>

                    {error && <div className="error-message">{error}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}

                    <form onSubmit={handleSubmit} className="users-form">
                        <div className="form-row">
                            <div className="form-group">
                                <label>User ID</label>
                                <input
                                    type="text"
                                    name="userId"
                                    value={formData.userId}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Role</label>
                                <select
                                    name="role"
                                    value={formData.role}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="USER">User</option>
                                    {userData?.role === 'MASTER' && (
                                        <option value="ADMIN">Admin</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <button 
                            type="submit" 
                            className="submit-button"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Creating...' : 'Create User'}
                        </button>
                    </form>

                    <div className="filters-container">
                        <input
                            type="text"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                        <select
                            value={filterRole}
                            onChange={(e) => setFilterRole(e.target.value)}
                            className="role-filter"
                        >
                            <option value="ALL">All Roles</option>
                            <option value="USER">User</option>
                            <option value="ADMIN">Admin</option>
                            {userData?.role === 'MASTER' && <option value="MASTER">Master</option>}
                        </select>
                    </div>

                    <div className="users-list">
                        <h2>Users List</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user.userId}</td>
                                        <td>{user.fullName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phoneNumber}</td>
                                        <td>{user.role}</td>
                                        <td>
                                            <button className="edit-button" onClick={() => handleEdit(user)}>Edit</button>
                                            <button 
                                                className="delete-button"
                                                onClick={() => handleDelete(user.userId)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {editingUser && (
                        <div className="edit-modal">
                            <div className="edit-modal-content">
                                <h2>Edit User</h2>
                                <form onSubmit={handleUpdate}>
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            value={editFormData.fullName}
                                            onChange={(e) => setEditFormData({
                                                ...editFormData,
                                                fullName: e.target.value
                                            })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            value={editFormData.email}
                                            onChange={(e) => setEditFormData({
                                                ...editFormData,
                                                email: e.target.value
                                            })}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone Number</label>
                                        <input
                                            type="tel"
                                            value={editFormData.phoneNumber}
                                            onChange={(e) => setEditFormData({
                                                ...editFormData,
                                                phoneNumber: e.target.value
                                            })}
                                            required
                                        />
                                    </div>
                                    <div className="button-group">
                                        <button type="submit" disabled={isLoading}>
                                            {isLoading ? 'Updating...' : 'Update'}
                                        </button>
                                        <button type="button" onClick={() => {
                                            setEditingUser(null);
                                            setEditFormData(null);
                                        }}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}