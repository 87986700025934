import React, { useState, useEffect } from 'react';
import './SubCategoryList.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const SubCategoryList = () => {
  const initialCategories = [
    {
      id: 1,
      masterCategory: 'Salon At Home',
      subCategory: 'Mehandi',
      services: ['Bridal Mehandi', 'Arabic Design', 'Traditional Design'],
      packages: ['Bridal Package - 2500', 'Simple Design - 1000', 'Kids Special - 500'],
      status: 'Active'
    },
    {
      id: 2,
      masterCategory: 'Salon At Home',
      subCategory: 'Facial + Waxing',
      services: ['Root touchup - Application', 'Anti-aging Facial', 'Gold Facial'],
      packages: [
        'Sit,Back & relax - 1999',
        'Super deals 1100',
        'Most Demanded',
        'Shine like a diva - 2499',
        'Tan FREE Skin - 1899',
        'VIP TREATMENT - 3999',
        'Brightens And Whitens - 2799',
        'Be Your Best (Rica wax + Facial) - 3499'
      ],
      status: 'Active'
    },
    {
      id: 3,
      masterCategory: 'Salon At Home',
      subCategory: 'Hair Treatment',
      services: ['Hair Spa', 'Keratin Treatment', 'Hair Color'],
      packages: [
        'Hair Spa Special - 1999',
        'Color & Care - 2499',
        'Keratin Premium - 4999',
        'Hair Fall Solution - 2999'
      ],
      status: 'Active'
    },
    {
      id: 4,
      masterCategory: 'Spa Services',
      subCategory: 'Body Massage',
      services: ['Swedish Massage', 'Deep Tissue Massage', 'Aromatherapy'],
      packages: [
        'Relaxation Package - 2999',
        'Deep Tissue Special - 3499',
        'Aromatherapy Bliss - 3999',
        'Couple Massage - 5999'
      ],
      status: 'Active'
    },
    {
      id: 5,
      masterCategory: 'Beauty Services',
      subCategory: 'Makeup',
      services: ['Bridal Makeup', 'Party Makeup', 'Professional Photoshoot'],
      packages: [
        'Bridal Premium - 15999',
        'Party Ready - 4999',
        'Photoshoot Perfect - 7999',
        'Engagement Special - 9999'
      ],
      status: 'Inactive'
    },
    {
      id: 6,
      masterCategory: 'Salon At Home',
      subCategory: 'Nail Care',
      services: ['Manicure', 'Pedicure', 'Nail Art'],
      packages: [
        'Basic Nail Care - 999',
        'Luxury Mani-Pedi - 1999',
        'Nail Art Special - 1499',
        'Premium Care - 2499'
      ],
      status: 'Active'
    },
    {
      id: 7,
      masterCategory: 'Spa Services',
      subCategory: 'Face Treatments',
      services: ['Face Massage', 'Face Pack', 'Steam'],
      packages: [
        'Glow Boost - 1999',
        'Anti-Aging Special - 3999',
        'Pigmentation Care - 2999',
        'Acne Treatment - 2499'
      ],
      status: 'Active'
    },
    {
      id: 8,
      masterCategory: 'Beauty Services',
      subCategory: 'Threading & Bleach',
      services: ['Eyebrow Threading', 'Face Bleach', 'Upper Lip'],
      packages: [
        'Face Glow Package - 999',
        'Complete Threading - 599',
        'Bleach Special - 1499'
      ],
      status: 'Active'
    },
    {
      id: 9,
      masterCategory: 'Salon At Home',
      subCategory: 'Kids Haircut',
      services: ['Basic Cut', 'Style Cut', 'Hair Spa'],
      packages: [
        'Kids Basic - 499',
        'Style Special - 799',
        'Complete Care - 1299'
      ],
      status: 'Active'
    },
    {
      id: 10,
      masterCategory: 'Beauty Services',
      subCategory: 'Bridal Package',
      services: ['Pre-Bridal', 'Wedding Day', 'Reception'],
      packages: [
        'Basic Bridal - 25999',
        'Premium Bridal - 35999',
        'Royal Bridal - 45999',
        'Complete Wedding Package - 99999'
      ],
      status: 'Active'
    }
  ];

  const [categories, setCategories] = useState(initialCategories);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedMasterCategory, setSelectedMasterCategory] = useState('');
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [newService, setNewService] = useState('');
  const [newPackage, setNewPackage] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(initialCategories);

  useEffect(() => {
    let filtered = [...initialCategories];

    if (selectedStatus) {
      filtered = filtered.filter(
        category => category.status.toLowerCase() === selectedStatus.toLowerCase()
      );
    }
    if (selectedMasterCategory) {
      filtered = filtered.filter(
        category => category.masterCategory === selectedMasterCategory
      );
    }
    if (searchTerm) {
      filtered = filtered.filter(
        category =>
          category.subCategory.toLowerCase().includes(searchTerm.toLowerCase()) ||
          category.masterCategory.toLowerCase().includes(searchTerm.toLowerCase()) 
      );
    }

    setFilteredCategories(filtered);
  }, [searchTerm, selectedStatus, selectedMasterCategory, initialCategories]);

  const handleAddService = (categoryId) => {
    setCurrentCategoryId(categoryId);
    setShowServiceModal(true);
  };

  const handleAddPackage = (categoryId) => {
    setCurrentCategoryId(categoryId);
    setShowPackageModal(true);
  };

  const submitNewService = () => {
    if (newService.trim()) {
      const updatedCategories = categories.map(category => {
        if (category.id === currentCategoryId) {
          return {
            ...category,
            services: [...category.services, newService]
          };
        }
        return category;
      });
      setCategories(updatedCategories);
      setFilteredCategories(updatedCategories);
      setNewService('');
      setShowServiceModal(false);
    }
  };

  const submitNewPackage = () => {
    if (newPackage.trim()) {
      const updatedCategories = categories.map(category => {
        if (category.id === currentCategoryId) {
          return {
            ...category,
            packages: [...category.packages, newPackage]
          };
        }
        return category;
      });
      setCategories(updatedCategories);
      setFilteredCategories(updatedCategories);
      setNewPackage('');
      setShowPackageModal(false);
    }
  };

  const handleDelete = (id) => {
    const updatedCategories = categories.filter(category => category.id !== id);
    setCategories(updatedCategories);
    setFilteredCategories(updatedCategories);
  };

  const uniqueMasterCategories = [...new Set(categories.map(cat => cat.masterCategory))];

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Sub Categories'}/>
            <div className="sub-salon-container">
              <div className="master-header">
                <button className="sub-new-category-btn">New Sub Category</button>
              </div>

              <div className="sub-filters">
                <select 
                  value={selectedStatus} 
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="sub-status-filter"
                >
                  <option value="">All Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>

                <select 
                  value={selectedMasterCategory}
                  onChange={(e) => setSelectedMasterCategory(e.target.value)}
                  className="sub-master-category-filter"
                >
                  <option value="">All Master Categories</option>
                  {uniqueMasterCategories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>

                <div className="sub-search-container">
                  <input
                    type="text"
                    placeholder="Search by sub-category name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="sub-search-input"
                  />
                </div>
              </div>

              <table className="sub-categories-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>M. Category</th>
                    <th>Sub Category</th>
                    <th>Services</th>
                    <th>Packages</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCategories.map((category, index) => (
                    <tr key={category.id}>
                      <td>{index + 1}</td>
                      <td>{category.masterCategory}</td>
                      <td>{category.subCategory}</td>
                      <td>{category.services.join(', ')}</td>
                      <td>
                        <ul className="sub-package-list">
                          {category.packages.map((pkg, index) => (
                            <li key={index}>{pkg}</li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <span className={`sub-status-badge ${category.status.toLowerCase()}`}>
                          {category.status}
                        </span>
                      </td>
                      <td className="sub-action-buttons">
                        <button onClick={() => handleAddService(category.id)}>Add Services</button>
                        <button onClick={() => handleAddPackage(category.id)}>Add Packages</button>
                        <button className="sub-edit-btn">Edit</button>
                        <button onClick={() => handleDelete(category.id)} className="sub-delete-btn">Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {showServiceModal && (
                <div className="sub-modal">
                  <div className="sub-modal-content">
                    <h3>Add New Service</h3>
                    <input
                      type="text"
                      value={newService}
                      onChange={(e) => setNewService(e.target.value)}
                      placeholder="Enter service name"
                    />
                    <div className="sub-modal-buttons">
                      <button onClick={submitNewService}>Add</button>
                      <button onClick={() => setShowServiceModal(false)}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}

              {showPackageModal && (
                <div className="sub-modal">
                  <div className="sub-modal-content">
                    <h3>Add New Package</h3>
                    <input
                      type="text"
                      value={newPackage}
                      onChange={(e) => setNewPackage(e.target.value)}
                      placeholder="Enter package name"
                    />
                    <div className="sub-modal-buttons">
                      <button onClick={submitNewPackage}>Add</button>
                      <button onClick={() => setShowPackageModal(false)}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
        </div>
    </div>
  );
};

export default SubCategoryList;