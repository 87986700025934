import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';
import AllJobs from './pages/jobs/AllJobs';
import HeroBannerSlide from './pages/herobanner/herobanner';
import OfferSlide from './pages/offerslider/offerslider';
import PromotionalBannerUpload from './pages/promotional/promotionalBanner';
import PackageSelector from './pages/packageSelector/packageSelector';
import ServicePackages from './pages/servicePackage/servicepackage';
import JobDashboard from './pages/todaysJobs/jobDasboard';
import Upcomingjobs from './pages/upcomingjobs/upcomingjobs';
import CancelledJobs from './pages/cancelledJobs/cancelledjobs';
import CreateJob from './pages/createJob/createJob';
import MasterCategoryList from './pages/masterCategory/masterCategory';
import CategoryList from './pages/category/Category';
import SubCategoryList from './pages/subcategory/subcategoryList';
import ServiceListing from './pages/services/ServiceListing';
import Packages from './pages/packageList/packageList';
import ServiceProvider from './pages/serviceProvider/serviceProvider';
import CustomerManagement from './pages/customer/customer';
import SlotManager from './pages/slotmanager/slotmanager';
import Coupons from './pages/coupons/coupons';
import HubMaster from './pages/hubMaster/hubMaster';
import ContactsTable from './pages/contact/Contact';
import ExpertsRequest from './pages/expertsRequest/expertsrequest';
import BlogListing from './pages/blogListing/blogListing';
import Profile from './pages/profile/profile';
import TomorrowOffers from './pages/tomorrowOffers/tomorrowOffers';
import PackageTable from './pages/productInventory/productInventory';
import Controls from './pages/controls/Controls';
import Users from './pages/users/Users';
import Sidebar from './components/sidebar/sidebar';

function App() {
  // Protected Route component
  const ProtectedRoute = ({ children }) => {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/login" 
            element={
              sessionStorage.getItem('authToken') ? 
              <Navigate to="/dashboard" replace /> : 
              <Login />
            } 
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobs/*"
            element={
              <ProtectedRoute>
                <AllJobs />
              </ProtectedRoute>
            }
          />
          <Route path="/hero-banner-slider" element={
            <ProtectedRoute>
              <HeroBannerSlide />
            </ProtectedRoute>
          } />
          <Route path="/offer-banners" element={<OfferSlide />} />
          <Route path="/promotional-banners" element={<PromotionalBannerUpload />} />
          <Route path="/most-recommended-services" element={<PackageSelector />} />
          <Route path="/tomorrows-offers" element={<TomorrowOffers />} />
          <Route path="/add-budget" element={<ServicePackages />} />
          <Route path="/job/today" element={<JobDashboard />} />
          <Route path="/job/upcoming" element={<Upcomingjobs />} />
          <Route path="/job/cancelled" element={<CancelledJobs />} />
          <Route path="/job/create" element={<CreateJob />} />
          <Route path="/master-category" element={<MasterCategoryList />} />
          <Route path="/category" element={<CategoryList />} />
          <Route path="/sub-category" element={<SubCategoryList />} />
          <Route path="/services" element={<ServiceListing />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/service-providers" element={<ServiceProvider />} />
          <Route path="/customers" element={<CustomerManagement />} />
          <Route path="/slot-master" element={<SlotManager />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/hub-master" element={<HubMaster />} />
          <Route path="/blog-master" element={<BlogListing />} />
          <Route path="/contacts" element={<ContactsTable />} />
          <Route path="/experts-request" element={<ExpertsRequest />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/product-inventory" element={<PackageTable />} />
          <Route path="/controls" element={<Controls />} />
          <Route path="/users" element={<Users />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;