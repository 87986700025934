import React from "react";
import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";

const banners = [
  { id: 1, src: "", link: "/", showOn: "desktop", status: "Active" },
  { id: 2, src: "", link: "/", showOn: "desktop", status: "Active" },
  { id: 3, src: "", link: "/", showOn: "desktop", status: "Active" },
  { id: 4, src: "", link: "/", showOn: "mobile", status: "Active" },
  { id: 5, src: "", link: "/", showOn: "mobile", status: "Active" },
  { id: 6, src: "", link: "/", showOn: "mobile", status: "Active" },
];

const OfferSlide = () => {
  return (
    <div className="container">
      <Sidebar/>
      <div className="inner-container">
        <Navbar title={'Offers'}/>
        <div className="hero-banner-slide">
      <div className="master-header">
       <button className="add-slide-btn">+ Add New Slide</button>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="table-cell">#</div>
          <div className="table-cell">Banner</div>
          <div className="table-cell">Link</div>
          <div className="table-cell">Show on</div>
          <div className="table-cell">Status</div>
          <div className="table-cell">Action</div>
        </div>
        {banners.map((banner) => (
          <div className="table-row" key={banner.id}>
            <div className="table-cell">{banner.id}</div>
            <div className="table-cell">
              <img src={banner.src} alt={`Banner ${banner.id}`} className="banner-image" />
            </div>
            <div className="table-cell">
              <a href={banner.link} className="view-link">View</a>
            </div>
            <div className="table-cell">{banner.showOn}</div>
            <div className="table-cell">
              <span className={`status ${banner.status.toLowerCase()}`}>{banner.status}</span>
            </div>
            <div className="table-cell">
              <button className="edit-btn">Edit</button>
              <button className="delete-btn">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
      </div>
    </div>
  );
};

export default OfferSlide;
