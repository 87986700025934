import React, { useState } from 'react';
import './ExpertsRequest.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const ExpertsRequest = () => {
  const [experts] = useState([]);

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={'Experts Request'}/>
            <div className="experts-container">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Email ID</th>
              <th>For Service</th>
              <th>For City</th>
              <th>For Hub</th>
            </tr>
          </thead>
          <tbody>
            {experts.map((expert) => (
              <tr key={expert.id}>
                <td>{expert.id}</td>
                <td>{expert.name}</td>
                <td>{expert.mobile}</td>
                <td>{expert.email}</td>
                <td>{expert.service}</td>
                <td>{expert.city}</td>
                <td>{expert.hub}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </div>
    </div>
  );
};

export default ExpertsRequest;