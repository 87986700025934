
import React, { useState } from 'react';
import './JobDashboard.css';
import Sidebar from '../../components/sidebar/sidebar';
import Navbar from '../../components/navbar/navbar';

const JobDashboard = () => {
  
  const [filterState, setFilterState] = useState({
    dateFrom: new Date().toISOString().slice(0, 10),
    dateTo: new Date().toISOString().slice(0, 10),
    city: '',
    locationType: '',
    service: '',
    status: '',
    orderId: '',
    customerEmail: '',
    customerMobile: '',
    customerName: '',
    spMobile: '',
    couponCode: ''
  });

  const [activeDropdown,setActiveDropdown]=useState(false)
  const [showFilters,setFilters]=useState(true)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSearch = () => {
    console.log('Search with filters:', filterState);
  }

  const handleClear = () => {
    setFilterState({
      dateFrom: '',
      dateTo: '',
      city: '',
      locationType: '',
      service: '',
      status: '',
      orderId: '',
      customerEmail: '',
      customerMobile: '',
      customerName: '',
      spMobile: '',
      couponCode: ''
    });
  };

  return (
    <div className='container'>
        <Sidebar/>
        <div className='inner-container'>
            <Navbar title={"Today's Jobs"}/>
            <div className="job-dashboard-container">
      <div className="total-jobs">
        <div className="filter-section">
          <div className="filter-header" onClick={()=>{setFilters(!showFilters)}}>
            <img src="/filter.svg" alt="filter" className="filter-icon" />
            <span>Filter</span>
          </div>
          
          <div className="filter-grid" style={{display:showFilters?"grid":"none"}}>
            <div className="filter-item">
              <label>Select Date From</label>
              <input
                type="date"
                name="dateFrom"
                value={filterState.dateFrom}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>Select Date To</label>
              <input
                type="date"
                name="dateTo"
                value={filterState.dateTo}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>City</label>
              <select
                name="city"
                value={filterState.city}
                onChange={handleInputChange}
              >
                <option value="">Select City</option>
                <option value="delhi">Delhi</option>
                <option value="mumbai">Mumbai</option>
                <option value="bangalore">Bangalore</option>
              </select>
            </div>
            <div className="filter-item">
              <label>Location Type (HUB)</label>
              <select
                name="locationType"
                value={filterState.locationType}
                onChange={handleInputChange}
              >
                <option value="">Select Location</option>
                <option value="north">North</option>
                <option value="south">South</option>
                <option value="east">East</option>
                <option value="west">West</option>
              </select>
            </div>
            <div className="filter-item">
              <label>Service</label>
              <select
                name="service"
                value={filterState.service}
                onChange={handleInputChange}
              >
                <option value="">Select Service</option>
                <option value="salon">Salon</option>
                <option value="spa">Spa</option>
                <option value="cleaning">Cleaning</option>
              </select>
            </div>
            <div className="filter-item">
              <label>Status</label>
              <select
                name="status"
                value={filterState.status}
                onChange={handleInputChange}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
            <div className="filter-item">
              <label>Order Id</label>
              <input
                type="text"
                name="orderId"
                placeholder="Order id"
                value={filterState.orderId}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>Customer Email id</label>
              <input
                type="email"
                name="customerEmail"
                placeholder="Email id"
                value={filterState.customerEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>Customer Mobile No</label>
              <input
                type="text"
                name="customerMobile"
                placeholder="Mobile No"
                value={filterState.customerMobile}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>Customer Name</label>
              <input
                type="text"
                name="customerName"
                placeholder="Name"
                value={filterState.customerName}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>SP Mobile No</label>
              <input
                type="text"
                name="spMobile"
                placeholder="SP Mobile No"
                value={filterState.spMobile}
                onChange={handleInputChange}
              />
            </div>
            <div className="filter-item">
              <label>Coupon Code</label>
              <input
                type="text"
                name="couponCode"
                placeholder="Coupon Code"
                value={filterState.couponCode}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          <div className="button-group" style={{display:showFilters?"flex":"none"}}>
            <button className="search-btn" onClick={handleSearch}>Search</button>
            <button className="clear-btn" onClick={handleClear}>Clear</button>
          </div>
        </div>

        <div className="jobs-header">
          <div className="total-count">Total Job : 1</div>
          <div className="action-buttons">
            <button className="export-btn">Export</button>
            <button className="generate-btn">Generate Lead</button>
          </div>
        </div>

        <div className="jobs-table">
          <table>
            <thead>
              <tr>
                <th>Job id</th>
                <th>SP Name</th>
                <th>Scheduled Date & Time</th>
                <th>HUB</th>
                <th>Category</th>
                <th>Customer details</th>
                <th>Customer Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>1730204269</div>
                  <button className="schedule-btn">Schedule</button>
                </td>
                <td></td>
                <td>
                  <div>2024-11-01</div>
                  <div className="time-slot">13:30 - 14:00</div>
                </td>
                <td>
                  <div>Reschedule Count</div>
                  <div>(0)</div>
                </td>
                <td>
                  <span className="category-tag">Salon At Home</span>
                </td>
                <td>
                  <div>Riya</div>
                  <div>riyagupta1994.rg@gmail.com</div>
                  <div>8690355744</div>
                </td>
                <td>
                  <div>E2-1101, Vatika Lifestyle homes, Vatika india next</div>
                  <div>Socity Name : Vatika lifestyle</div>
                </td>
                <td>
                  <div className="dropdown">
                    <button className="job-action-btn" onClick={()=>{setActiveDropdown(!activeDropdown)}}>Action</button>
                    {activeDropdown && <div className="dropdown-content">
                      <a href="/">Assign SP</a>
                      <a href="/">Edit Job</a>
                      <a href="/">Duplicate Job</a>
                      <a href="/">Reschedule</a>
                      <a href="/">Close</a>
                      <a href="/">Cancel</a>
                    </div>}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
        </div>
    </div>
  );
};

export default JobDashboard;